import {DeleteRounded, FilterAltRounded, ViewColumnRounded,} from "@mui/icons-material";
import {CircularProgress, Divider, IconButton, Stack, TableCell, TableRow, Tooltip, Typography,} from "@mui/material";
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions, TableFilterList, TableToolbar,} from "mui-datatables";
import React, {FC, ReactNode, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import useDialog from "../../hooks/useDialog";
import {useUploadQuery} from "../../hooks/useUploadQuery";
import {setLoading} from "../../redux/features/app";
import {EnrollmentType} from "../../types";
import StudentInfoDialog from "../dialogs/StudentInfo.dialog";
import {useDelete} from "../../hooks/useDelete";
import {usePointerTheme} from "../../hooks/usePointerTheme";
import {deleteStudent, getStudent} from "../../requests/student.request";
import {useHistory, useParams} from "react-router-dom";
import {getPromotionalReportById} from "../../requests/promotionalReport.request";
import flat from "flat";
import {useQueryClient} from "react-query";
import {useNotificationPromotionReportQuery} from "../../hooks/useNotificationPromotionReportQuery";
import Button from "@mui/material/Button";
import {blue} from "@mui/material/colors";
import DownloadIcon from "@mui/icons-material/Download";
import {useNotificationPromotionReportCountQuery} from "../../hooks/useNotificationPromotionReportCountQuery";


interface IPromotionalReportTableProps {
    title: string;
}

const UnsuccessfulPRTable: FC<IPromotionalReportTableProps> = ({title}) => {
    const history = useHistory();
    const {enrollmentId} = useParams() as {
        enrollmentId: string
    };
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    programId: "all",
  });

  const { data: uploadedData, isLoading } = useNotificationPromotionReportQuery({
    pageSize: 100,
    filters,
      enrollmentId,
      ...{seen: true},
      ...title && {header:title}
  });





    const handleDelete = useDelete(
      "Delete Student",
      "enrollment",
      deleteStudent,

  );


  const onDeleteRow = (e: any, name: any) => {
   return  handleDelete(e, name )
  }

  const columns: MUIDataTableColumn[] =     [
      {
        name: "studentNumber",
        label: "STUDENT NO.",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "student",
        label: "FULL NAME",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "yearLevel",
        label: "YEAR LEVEL",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "program",
        label: "Program Name",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "message",
        label: "Remarks",

        options: {
          display: true,
          viewColumns: false,
          filter: true,
            customBodyRender: (value: any, tableMeta, updateValue: any) => {
                return (<div style={{ whiteSpace: "pre-wrap"}}>
                    {value}
                </div>);
            }
        },
      }
  ];

    const options: MUIDataTableOptions = {
        textLabels: {
            body: {
                noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
            }
        },
        serverSide: true,
        filterType: "dropdown",
        tableBodyMaxHeight: "600px",
        print: false,
        selectableRows: "none",
        tableId: "enrollmenttable",
        download: false
    };

    useEffect(() => {
        dispatch(setLoading(isLoading));
    }, [isLoading]);

    return (
        <>
            {(uploadedData?.data as any)?.file ?
                <a href={(uploadedData?.data as any)?.file}  download={(uploadedData?.data as any).message + "_" +"Unsuccessful_PR_EL.xlsx"} style={{textDecoration: 'none'}}>
                    <Button variant="contained" color="info" style={{marginBottom: 12}} startIcon={<DownloadIcon/>}>
                        Download Unsuccessful  {title == "ENROLLMENT LIST" ? "Enrollment" : "PR" }
                    </Button>
                </a> : null}
            <Divider></Divider>
            <MUIDataTable
                title={""}
                data={(uploadedData?.data as any)?.data ?? []}
                columns={columns}
                options={options}
                components={{
                    TableFilterList: (props) => <TableFilterList {...props} />,
                    TableToolbar: (props) => {
                        return <TableToolbar {...props} />;
                    },
                    icons: {
                        FilterIcon: FilterAltRounded as unknown as ReactNode,
                        ViewColumnIcon: ViewColumnRounded as unknown as ReactNode,
                    },
                }}
            />
        </>
    );
};

export default UnsuccessfulPRTable;
