import {Redirect, Route, RouteProps} from 'react-router-dom'
import {routes} from '../config'
import {Layout} from './Layout'
import {PageDefault} from './PageDefault';
import {Route as AppRoute} from '../types';
import {useSelector} from 'react-redux';
import type {RootState} from '../redux/store';
import AccountSetting from '../pages/AccountSetting.page';
import useLogSignalr from "../hooks/useLogSignalr";
import {useEffect} from "react";

function Private() {
    const authState = useSelector((state: RootState) => state.auth)

    const addRoute = (route: AppRoute) => (
        <PrivateRoute

            key={route.key}
            path={route.path}
            component={route.component || PageDefault}
            exact
        />
    );
    const {
        initAppSignalR,
        onAddLog,
        onAppConnection,
        destroyAppSignalR
    } = useLogSignalr();

    useEffect(() => {
        try {
            initAppSignalR();
            onAppConnection('OnAddLog', onAddLog);
        } catch (e) {

        }
        return () => destroyAppSignalR();
    }, [authState.token]);

    return (
        <>
            <PrivateRoute path='/account-settings' component={AccountSetting} exact/>
            {routes.map((route: AppRoute) => {
                // if (route.roles?.includes(authState.user?.roles?.toLocaleLowerCase() ?? 'user')) {
                return route.subRoutes ? route.subRoutes.map((item: AppRoute) => addRoute(item)) : addRoute(route)
                // }
            }
            )}
        </>
    )
}

export default Private

export const PrivateRoute = ({ component: Component, ...rest }: { component: any } & RouteProps) => {

    const authStore = useSelector((state: RootState) => state.auth)
    return (
        <Route   {...rest} render={(props) => (
            authStore.isAuth
                ? <Layout  >

                        <Component   {...props} />
                </Layout>
                : <Redirect to='/login' />
        )} />
    )
}
