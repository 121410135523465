import {
  ClearAllRounded,
  DashboardRounded,
  EventNoteRounded,
  LocalLibraryRounded,
  SchoolRounded,
  SettingsRounded,
  SummarizeRounded,
  UploadRounded,
} from "@mui/icons-material";
import DashboarPage from "../pages/Dashboard.page";
import SettingPage from "../pages/Setting.page";

import EnrollmentPage from "../pages/Enrollment.page";
import SchoolListPage from "../pages/SchoolList.page";

import {Route} from "../types/Route";
import LogsPage from "../pages/Logs.page";
import SchoolPage from "../pages/School.page";
import StudentListPage from "../pages/StudentList.page";
import PromotionalReportPage from "../pages/PromotionalReport.page";
import StudentPage from "../pages/Student.page";
import AcademicCalendar from "../pages/AcademicCalendar.page";

const routes: Array<Route> = [
  {
    key: "router-dashboard",
    title: "Dashboard",
    description: "Dashboard",
    component: DashboarPage,
    path: "/",
    isEnabled: true,
    icon: DashboardRounded,
    hidden: false,
    roles: ["admin", "user", "superadmin"],
  },

  {
    key: "router-schools",
    title: "Schools",
    secondName: "HEI Profile",
    description: "HEI Profile",
    component: SchoolListPage,
    path: "/schools",
    isEnabled: true,
    icon: SchoolRounded,
    hidden: false,
    roles: ["superadmin"],
  },
  {
    key: "router-schools-id",
    title: "Schools",
    description: "Schools",
    path: "/schools/:schoolId",
    component: SchoolPage,
    isEnabled: true,
    icon: SchoolRounded,
    hidden: true,
    roles: ["admin", "user", "superadmin"],
  },
  {
    key: "router-academic-calendar",
    title: "Academic Calendar",
    description: "Academic Calendar",
    component: AcademicCalendar,
    path: "/academic/calendar",
    isEnabled: true,
    icon: EventNoteRounded,
    hidden: false,
    roles: ["superadmin"],
  },{
    key: "router-academic-calendar-id",
    title: "Academic Calendar",
    description: "Academic Calendar",
    component: AcademicCalendar,
    path: "/academic/calendar/:academicCalendarId",
    isEnabled: false,
    icon: EventNoteRounded,
    hidden: true,
    roles: ["superadmin"],
  },
  {
    key: "router-schools-programs-id",
    title: "Schools",
    description: "Schools",
    path: "/schools/:schoolId/programs/:programId",
    isEnabled: true,
    icon: SchoolRounded,
    hidden: true,
    roles: ["admin", "user", "superadmin"],
  },
  {
    key: "router-schools-programs-curriculums-id",
    title: "Schools",
    description: "Schools",
    path: "/schools/:schoolId/programs/:programId/curriculums/:curriculumId",
    isEnabled: true,
    icon: SchoolRounded,
    hidden: true,
    roles: ["admin", "user", "superadmin"],
  },
  {
    key: "router-students",
    title: "Students",
    description: "Students",
    component: StudentListPage,
    path: "/students",
    isEnabled: true,
    icon: LocalLibraryRounded,
    hidden: false,
    roles: ["admin", "user", "superadmin"],
  },
  {
    key: "router-students-schools",
    title: "SchoolsStudents",
    description: "SchoolsStudents",
    component: StudentListPage,
    path: "/students/school/:schoolId",
    isEnabled: true,
    icon: LocalLibraryRounded,
    hidden: false,
    roles: [],
  },

  {
    key: "router-student",
    title: "Students",
    description: "Students",
    path: "/students/:studentId",
    component: StudentPage,
    isEnabled: true,
    icon: SchoolRounded,
    hidden: true,
    roles: ["admin", "user", "superadmin"],
  },
  {
    key: "router-enrollments",
    title: "Enrollments",
    description: "Enrollments",
    component: EnrollmentPage,
    path: "/enrollments",
    isEnabled: true,

    icon: SummarizeRounded,
    hidden: false,
    roles: ["admin", "user", "superadmin"],
  },
  {
    key: "router-enrollments-id",
    title: "Enrollments",
    description: "Enrollments",
    component: EnrollmentPage,
    path: "/enrollments/:enrollmentId",
    isEnabled: true,
    icon: SummarizeRounded,
    hidden: true,
    roles: ["admin", "user", "superadmin"],
  },
  {
    key: "router-promotional-report",
    title: "Promotional Report 	",
    description: "Promotional Report",
    component: PromotionalReportPage,
    path: "/promotional-report",
    isEnabled: true,
    icon: UploadRounded,
    hidden: true,
    roles: ["admin", "user", "superadmin"],
  },
  {
    key: "router-settings",
    title: "Settings",
    description: "Settings",
    component: SettingPage,
    path: "/settings",
    isEnabled: true,
    icon: SettingsRounded,
    hidden: false,
    roles: ["admin", "user", "superadmin"],
  },
  {
    key: "router-logs",
    title: "Logs",
    description: "Logs",
    component: LogsPage,
    path: "/log",
    isEnabled: true,
    icon: ClearAllRounded,
    hidden: false,
    roles: ["admin", "user", "superadmin"],
  },
  // {
  // 	key: 'router-dashboard',
  // 	title: 'Dashboard',
  // 	description: 'Dashboard',
  // 	path: '/dashboard',
  // 	isEnabled: true,
  // 	icon: DashboardIcon,
  // },
  // {
  // 	key: 'router-gh',
  // 	title: 'GitHub',
  // 	description: 'GitHub',
  // 	isEnabled: true,
  // 	icon: GitHubIcon,
  // 	subRoutes: [
  // 		{
  // 			key: 'router-gh-public',
  // 			title: 'Public Repos',
  // 			description: 'Public Repos',
  // 			path: '/gh/public',
  // 			isEnabled: true,
  // 			icon: PublicIcon,
  // 		},
  // 		{
  // 			key: 'router-gh-private',
  // 			title: 'Private Repos',
  // 			description: 'Private Repos',
  // 			path: '/gh/private',
  // 			isEnabled: false,
  // 			icon: PrivateIcon,
  // 		},
  // 	],
  // },
  // {
  // 	key: 'router-code',
  // 	title: 'Code Editor',
  // 	description: 'Code Editor',
  // 	path: '/code-editor',
  // 	isEnabled: true,
  // 	icon: CodeIcon,
  // 	appendDivider: true,
  // },
  // {
  // 	key: 'router-my-account',
  // 	title: 'My Account',
  // 	description: 'My Account',
  // 	path: '/account',
  // 	isEnabled: true,
  // 	icon: UserIcon,
  // 	subRoutes: [
  // 		{
  // 			key: 'router-settings',
  // 			title: 'Settings',
  // 			description: 'Account Settings',
  // 			path: '/account/settings',
  // 			isEnabled: true,
  // 			icon: SettingsIcon,
  // 		},
  // 		{
  // 			key: 'router-preferences',
  // 			title: 'Preferences',
  // 			description: 'Account Preferences',
  // 			path: '/account/preferences',
  // 			isEnabled: true,
  // 			icon: ListIcon,
  // 		},
  // 		{
  // 			key: 'router-billing',
  // 			title: 'Billing',
  // 			description: 'Account Billing',
  // 			path: '/account/billing',
  // 			isEnabled: true,
  // 			icon: BillingIcon,
  // 		},
  // 	],
  // },
];

export default routes;
