import {AddRounded, SearchRounded} from "@mui/icons-material"
import {
    Autocomplete,
    Box,
    Button,
    Container,
    Divider,
    InputAdornment,
    List,
    Paper,
    TableFooter,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from "@mui/material"
import React, {useMemo} from "react"
import {Helmet} from "react-helmet-async"
import AddSchool from "../components/dialogs/AddSchool.dialog"
import SchoolList from "../components/List/SchoolList"
import {useDebounce} from "../hooks/useDebounce"
import useDialog from "../hooks/useDialog"
import {useSchoolQuery} from "../hooks/useSchoolQuery"
import {useAppSelector} from "../redux/store";
import {TablePaginationActions} from "../components/Table/TablePaginationAction";
import {useRegionSelectQuery} from "../hooks/useRegionQuery";
import {useDispatch} from "react-redux";
import {setPostCount} from "../redux/features/school";
import IsLoading from "../components/Searching";


const SchoolListPage = () => {
    const [searchStr, setSearchStr] = React.useState('')
    const debouncedSearchStr = useDebounce(searchStr, 500)
    const [page, setPage] = React.useState(0);
    const postCount = useAppSelector((store) => store?.school?.postCount);

    const dispatch = useDispatch();
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [selectedStatusId, setSelectedStatusId] = React.useState<any>( {
        id: undefined,
        name: "All"
    });
    const [selectedRegionId, setSelectedRegionId] = React.useState<any>({
        id: undefined,
        name: "All"
    });
    const {data: regions} = useRegionSelectQuery()
    const {data: schools, isLoading, isFetching, refetch} = useSchoolQuery(
        {
            pageSize: rowsPerPage,
            search: debouncedSearchStr,
            ...(postCount && {postCount: postCount}),
            ...(selectedRegionId?.id != 0 && {regionId: selectedRegionId.id}),
            ...(selectedStatusId?.id != 0 && {status: selectedStatusId.id}),
            ...(page > -1 && {page: page + 1}),
        })

    const { handleOpen: handleOpenAddSchool, handleClose: handleCloseAddSchool, open: openAddSchool } = useDialog()
    const roles = useAppSelector((store) => store?.auth?.user?.roles);
    const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);





    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (schools?.data ?? []).length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const statusFilterMemo = useMemo(() =>{
        return [
            {
                id: 0,
                name: "All"
            },
            /*{
                id: "Active",
                name: "Active",
                color: "success.main"
            },*/

            {
                id: "Inactive",
                name: "Inactive",
                color: "error.main"
            }, {
                id: "Pending",
                name: "Pending",
                color: "warning.main"
            },
            {
                id: "Rejected",
                name: "Rejected",
                color: "error.main"
            },{
                id: "Approved",
                name: "Approved",
                color: "success.main"
            },

        ]
    }, [])


    return (
        <>
            <Helmet>
                <title>HEI Profile</title>
            </Helmet>

            <Box
                component='main'
                sx={{ flexGrow: 1, pt: 10 }}
                style={{paddingBottom: "20px", paddingLeft:" 20px",
                    paddingRight: "20px"}}

            >
                <Container>
                <Paper>
                    <List
                        dense
                        subheader={
                            <>
                                <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                                    <Typography variant="h5" fontWeight="bold">HEI Profile</Typography>
                                    <Box
                                        display="flex" alignItems="center"
                                        gap={2}
                                    >
                                        {((roles?.toLowerCase() == "superadmin" || typeId == 3)) ?
                                            <form onSubmit={(event) => event.preventDefault()}>
                                                <>
                                                    <Autocomplete id={"superadmindashboardschoolyear"}
                                                                  disabled={isLoading}
                                                                  disableClearable
                                                                  size="small"
                                                                  value={selectedRegionId}
                                                                  onChange={(event: any, newValue: string | null) => {
                                                                      setSelectedRegionId(newValue ? newValue : {});
                                                                  }}
                                                                  getOptionLabel={(option) => {
                                                        return option.name
                                                    }
                                                    }
                                                    renderOption={(props, option) => (
                                                        <li {...props}  id={"seregionselevtntting" + option.id} key={option.id}>
                                                            <div  style={{flex: 1, display: "flex", flexDirection: "column" }}>
                                                                { option.name }</div>
                                                        </li>
                                                    )}
                                                                  options={[{
                                                                      name: "All",
                                                                      id: 0
                                                                  }, ...(regions || [])] || []}
                                                                  style={{width: 300}}

                                                                  renderInput={(params) => (
                                                                      <TextField {...params} label="Select Region"/>
                                                                  )}
                                                    />
                                                </>
                                            </form> : null}
                                        {typeId == 2 || typeId == 3 || roles == "SuperAdmin" ?
                                            <form onSubmit={(event) => event.preventDefault()}
                                                  autoComplete={"new-password"}>
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchRounded/>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    placeholder="Search School"
                                                    sx={{}}

                                                    label="Search School"
                                                margin="dense"
                                                size="small"
                                                value={searchStr}
                                                onChange={(e) => {
                                                    setPage(0)
                                                    setSearchStr(e.target.value)
                                                }}
                                            />
                                        </form>: null}
                                        {typeId == 2 || roles == "SuperAdmin" ? <Autocomplete
                                            id={"superstatus"}
                                            style={{width: 200, marginLeft: 6}}
                                            disabled={isLoading}
                                            disableClearable
                                            size="small"

                                            value={selectedStatusId}
                                            onChange={(event: any, newValue: string | null) => {
                                                setSelectedStatusId(newValue ? newValue : {});
                                            }}
                                            getOptionLabel={(option) => {
                                                return option.name
                                            }
                                            }
                                            renderOption={(props, option) => (
                                                <li {...props}  id={"setstudesschoollistting" + option.id} key={option.id}>
                                                    <Typography color={option.color ? option.color : "#000"}>{ option.name }</Typography>
                                                </li>
                                            )}
                                            options={statusFilterMemo || []}
                                            sx={{ width: 500 }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Select Status" />
                                            )}
                                        /> : null}
                                        {typeId == 2 || roles == "SuperAdmin" ? <Button
                                            variant="contained"
                                            startIcon={<AddRounded />}
                                            onClick={handleOpenAddSchool}
                                        >
                                            Add School
                                        </Button> : null}
                                    </Box>

                                </Box>
                                <Divider />
                            </>
                        }
                    >
                        <Box
                            sx={{
                                mt: 1,
                                overflowY: "scroll",
                                height: 'calc(100vh - 200px)'
                            }}
                        >

                            <SchoolList schools={schools?.data ?? []} />
                            {
                                (schools?.data?.length === 0 || !schools) && (
                                    <Box display="flex" justifyContent="center" flexDirection={"column"}
                                         alignItems="center" p={3}>
                                        <IsLoading loading={isLoading} fetching={isFetching}/>
                                        <Typography variant="body1">
                                            {isLoading || isFetching ? "Please wait while we load your content." : "Sorry, no matching records can be found."}
                                        </Typography>
                                    </Box>
                                )
                            }


                        </Box>
                    </List>
                    {(schools?.data ?? [])?.length > 0 ?<div style={{flex: 1, display: "flex", flexDirection: "row-reverse"}}>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={3}
                                    count={(schools?.totalCount) ?? 10}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelDisplayedRows={(page) =>
                                      true ? `${page.from}-${page.to === -1 ? page.count : page.to} of ${
                                            page.count
                                        }` : ""
                                    }
                                    ActionsComponent={TablePaginationActions}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </div>:null}

                </Paper>

                </Container>
            </Box>
            <AddSchool open={openAddSchool} handleClose={() =>{
                dispatch(setPostCount())
                refetch()
                handleCloseAddSchool()
            }} />

        </>
    )
}

export default React.memo(SchoolListPage)
