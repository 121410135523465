import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {Alert, Box, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField} from '@mui/material';
import { useFormik } from 'formik';
import { register } from '../../requests/auth.request';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { ISnackbarSlice, openSnackbar } from '../../redux/features/snackbar';
import {useMutation, useQueryClient} from 'react-query';
import {setErrorSnackbar} from "../../helper/utils/helper";
import {postStudent} from "../../requests/student.request";
import SearchAsYouType from "../SearchAsYouType";
import {useCallback, useEffect} from "react";
import {SearchSchoolResponseType} from "../../types";
import {setAddStudentCount} from "../../redux/features/student";
import {useAddEntities} from "../../hooks/useAddEntities";

const validationSchema = yup.object({
    studentNumber: yup.string().required('Student Number is required'),
    firstName: yup.string().required('First name is required'),
    middleName: yup.string().required('Middle name is required'),
    lastName: yup.string().required('Last name is required'),
    extName: yup.string(),
    sex: yup.string(),
    nationality: yup.string(),
    age: yup.number().required('Age is required'),
})

const initialValues = {
    schoolId: '',
    studentNumber: new Date()?.getFullYear()?.toString(),
    firstName: '',
    middleName: '',
    lastName: '',
    extName: '',
    sex: "MALE",
    nationality: "",
    age: 0,
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function useAddStudentDialog() {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch()
    const {schoolId, regionId} = useAddEntities();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function Component() {
        const dispatch = useDispatch()
        const queryClient = useQueryClient()

        const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
            dispatch(openSnackbar(payload))
        }, [])

         const formik  = useFormik({
            initialValues,
            validationSchema,
            onSubmit: async (values, actions) => {
                try {
                    values.schoolId = schoolId
                    if(!schoolId){
                        setSnackbarStore({
                            message: "School Id is required",
                            severity: "error",
                            open: true,
                        })
                        return
                    }
                    await postStudent(values)
                    queryClient.invalidateQueries("students");
                    setSnackbarStore({
                        message: "Successfully added Student",
                        severity: "success",
                        open: true,
                    })
                    actions.resetForm()
                    dispatch(setAddStudentCount())
                    handleClose()
                } catch (error: any) {
                    console.error({ error })
                    setErrorSnackbar(error, setSnackbarStore)
                }
            }
        })

        const handleSubmit = React.useCallback(() => {
            formik.handleSubmit()
        }, [formik])


        const handleOnSelectedSchool = useCallback((value: SearchSchoolResponseType) => {
            formik.setFieldValue('schoolId', value?.id ?? "")
        }, [formik])




        return (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Add Student</DialogTitle>
                <DialogContent>
                    <Alert severity='warning'>
                        Only add student when not parsed by the system during the uploading of Promotional Report
                        <br />
                        Note: Fields with <strong>*</strong> are required
                    </Alert>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mb: 3,
                        }}
                    >

                        <TextField
                            label="Student ID"
                            variant="outlined"
                            margin="dense"
                            size='small'
                            fullWidth
                            required
                            id='studentNumber'
                            name='studentNumber'
                            value={formik.values.studentNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.studentNumber && Boolean(formik.errors.studentNumber)}
                            helperText={formik.touched.studentNumber && formik.errors.studentNumber}
                        />

                        <TextField
                            label="First Name"
                            variant="outlined"
                            margin="dense"
                            size='small'
                            fullWidth
                            required
                            id='firstName'
                            name='firstName'
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                        />
                        <TextField
                            label="Middle Name"
                            variant="outlined"
                            margin="dense"
                            size='small'
                            fullWidth
                            id='studentmiddleName'
                            name='middleName'
                            value={formik.values.middleName}
                            onChange={formik.handleChange}
                            error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                            helperText={formik.touched.middleName && formik.errors.middleName}
                        />

                        <Grid container gap={2} >
                            <Grid item flex={1}>
                                <TextField
                                    label="Last Name"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='studentlastName'
                                    name='lastName'
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <TextField
                                    label="Extension Name"
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    id='studentextName'
                                    name='extName'
                                    value={formik.values.extName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.extName && Boolean(formik.errors.extName)}
                                    helperText={formik.touched.extName && formik.errors.extName}
                                />
                            </Grid>
                        </Grid>
                        <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                        <RadioGroup
                            row
                            onChange={(event) => formik.setFieldValue('sex', event.target.value)}
                            value={formik.values.sex}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="MALE"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                            <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                        </RadioGroup>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button id={"cancelStudent"} onClick={handleClose}>Cancel</Button>
                    <Button id={"AddStudent"}  disabled={!(formik.isValid && formik.dirty)}  variant='contained' onClick={handleSubmit}>Add</Button>
                </DialogActions>
            </Dialog>
        )
    }
    return { Component, handleClickOpen, handleClose }
}
