import {useDispatch} from "react-redux";
import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {HttpTransportType, HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {BASE_URL, setHeaderToken} from "../requests/config";
import {useAppSelector} from "../redux/store";
import {useSnackbar} from "notistack";
import {onLogin} from "../redux/features/auth";

function useLogSignalr() {
    const dispatch = useDispatch();
    const user = useAppSelector((store) => store?.auth)
    const signalr = useRef<HubConnection | null>(null);
    const [connectionStatus, setConnectionStatus] = useState('');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [userToken, setUserToken] = useState("");
    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'session') {
                const updatedSession = JSON.parse(event.newValue || 'null');
                setUserToken(updatedSession?.token);
            }
        };

        const localStorageRefreshToken = localStorage.getItem('session');
        const sessionToken = JSON.parse(localStorageRefreshToken || 'null');
        if (sessionToken?.token) {
            setHeaderToken(sessionToken?.token);
            setUserToken(sessionToken?.token);
            dispatch(
                onLogin({
                    ...user,
                    token: sessionToken?.token,
                    isAuth: true,
                })
            );
        }


        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [userToken]);
    const initAppSignalR = useCallback(async () => {
        signalr.current = new HubConnectionBuilder()
            .withUrl(`${BASE_URL}/loghub`, {
                transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
                accessTokenFactory: () => userToken ?? user?.token
            })
            .withAutomaticReconnect()
            .build();
        signalr.current.onclose(() => setConnectionStatus('disconnected'));
        signalr.current.onreconnected(() => {
            setConnectionStatus('connected')
        });
        signalr.current.onreconnecting(() => {
            var localStorageRefreshToken: any = localStorage.getItem('session')
            var sessionToken = JSON.parse(localStorageRefreshToken);
            const accessToken = sessionToken?.token
            setUserToken(accessToken)
            setConnectionStatus('reconnecting')
        });
        signalr.current.start().then(() => setConnectionStatus('connected'));
    }, [userToken, user?.token]);
    const destroyAppSignalR = useCallback(() => {
        signalr.current?.stop();
    }, [user.token]);

    const onAppConnection = useCallback((connection: any, callback = () => {
        }) =>
            signalr.current?.on(connection, callback),
        []);


    async function onAddLog(id?: any, data?: any) {
        console.log(data)
        enqueueSnackbar(data)
    }

    useEffect(() => {
        return () => {
            destroyAppSignalR()
        }
    }, [])


    return {
        initAppSignalR,
        onAddLog,
        onAppConnection,
        destroyAppSignalR
    };
}

export default useLogSignalr
