
import { useQuery } from 'react-query';
import {getUserByName} from "../requests/user.request";
import {useCallback} from "react";
import {GetUsersResponseType, ProgramResponseType} from "../types";
import flat from "flat";


export const useSearchUserQuery = (str: string, schoolId: any, status: any ) => {
        return useQuery(['user', str, schoolId?.status, status, schoolId.regionId, schoolId.status, schoolId.email, schoolId.role, schoolId.sortOrder,  schoolId.schoolId,  schoolId.pageSize,  schoolId.page], ({ signal }) => getUserByName(str, signal, schoolId), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select:useCallback((data: GetUsersResponseType) => {
            return {
                ...data,
                data: data?.data?.map((user) => {
                    return {
                        ...user,
                        fullName: `${user?.userProfile?.firstName ?? ""} ${user?.userProfile?.middleName?? ""} ${user?.userProfile?.lastName?? ""}`,
                        ...flat(user, { safe: true }) as object,

                    }
                })
            }
        }, []),
    });
}
