import Axios from "axios";
import tokenRefresher, {setNewTokens} from '../helper/utils/axios-jwtoken-refresher'



export const BASE_URL = "https://edcs.ustp.edu.ph";
export const api = Axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        accept: "*",
    },
});
export const refreshTokenEndpoint = 'refresh-token'
export const accessTokenResultPath = 'accessToken'
export const refreshTokenResultPath = 'refreshToken'
export const headerOptions = {
    authPrefix: "Authorization",
    bearerPrefix: "Bearer"
};


api.interceptors.request.use(
    async (config: any) => {


        var localStorageRefreshToken: any = localStorage.getItem('session')
        var sessionToken = JSON.parse(localStorageRefreshToken);
        if (config.headers["Authorization"] && sessionToken?.token.trim()) {
            config.headers["Authorization"] = 'Bearer ' + sessionToken?.token;
        }
        return config;
    },
    async (error) => {
        if (error?.response?.status === 401) {
            await setNewTokens({
                refreshTokenEndpoint: refreshTokenEndpoint,
                accessTokenResultPath: accessTokenResultPath,
                refreshTokenResultPath: refreshTokenResultPath,
                headerOptions: headerOptions
            })
        }
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    async (config) => {
        if (config.config.method === 'get') {
            const dataToStore = {
                data: config.data,
                status: config.status,
                statusText: config.statusText,
                headers: config.headers
            };

        }
        var localStorageRefreshToken: any = localStorage.getItem('session')
        var sessionToken = JSON.parse(localStorageRefreshToken);
        if (config.headers["Authorization"]) {
            config.headers["Authorization"] = 'Bearer ' + sessionToken?.token.trim();
        }
        return config;
    },
    async (error) => {
        if (error?.response?.status === 401) {
            await setNewTokens({
                refreshTokenEndpoint: refreshTokenEndpoint,
                accessTokenResultPath: accessTokenResultPath,
                refreshTokenResultPath: refreshTokenResultPath,
                headerOptions: headerOptions
            })
        }


        return Promise.reject(error);
    }
);
export default api;
tokenRefresher.interceptRequest({
    refreshTokenEndpoint: refreshTokenEndpoint,
    accessTokenResultPath: accessTokenResultPath,
    refreshTokenResultPath: refreshTokenResultPath,
    headerOptions: headerOptions
});

export const setHeaderToken = (token: string) => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const removeHeaderToken = () => {
    api.defaults.headers.common["Authorization"] = null;
};
