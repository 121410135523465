import {
    AddRounded,
    CopyAllOutlined,
    DeleteRounded,
    DownloadRounded,
    EditRounded,
    FilterAltRounded,
    ViewColumnRounded,
} from "@mui/icons-material";
import {Box, Button, IconButton, Stack, Tooltip, Typography,} from "@mui/material";
import MUIDataTable, {
    MUIDataTableColumn,
    MUIDataTableOptions,
    MUIDataTableToolbar,
    TableFilterList,
    TableToolbar,
} from "mui-datatables";
import React, {FC, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDeleteDialog} from "../../redux/features/app";
import {deleteCurriculum} from "../../requests/curriculum.request";
import AddCurriculum from "../dialogs/AddCurriculum.dialog";
import {RootState, useAppSelector} from "../../redux/store";
import {
    setCurriculum,
    setCurriculumBreadcrumb,
    setCurriculumId,
    setCurriculumTabState,
    setTabValue,
} from "../../redux/features/school";
import {useAddEntities} from "../../hooks/useAddEntities";
import {useDebounce} from "../../hooks/useDebounce";
import {useSearchCurriculumQuery} from "../../hooks/useSearchCurriculumQuery";
import {onDownloadDocument} from "../../helper/utils/helper";
import {usePager} from "../../hooks/usePager";
import {
    downloadExcelCourseCurriculumLinkTemplate,
    downloadExcelCurriculumLinkTemplate
} from "../../requests/download.request";
import {usePointerTheme} from "../../hooks/usePointerTheme";
import {openSnackbar} from "../../redux/features/snackbar";
import {useQueryClient} from "react-query";
import EditCurriculum from "../dialogs/EditCurriculum.dialog";
import useDialog from "../../hooks/useDialog";
import {
    editCurriculimSchoolId,
    editCurriculimSchoolName,
    setColoredRows,
    setCurriculumCount,
    setEditCurriculum
} from "../../redux/features/curriculum";
import {useStyles} from "../../styles/fade";
import UploadCourseCurriculumDialog from "../dialogs/UploadCourseCurriculum.dialog";

interface ICurriculumTableProps {
    title: string;
    setting?: boolean;
}

const CurriculumTable: FC<ICurriculumTableProps> = ({title, setting}) => {
    const dispatch = useDispatch();
    const {schoolId, regionId} = useAddEntities();
    const [page, setPage] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const programId = useAppSelector((store) => store?.school?.programId);
    const [name, setName] = React.useState<any>("");
    const debouncedName = useDebounce(name, 500);

    const [selectedProgram, setSelectedProgram] = useState<any>();

    const handleDeleteCurriculum = (id: string | number, name: string) => {
        dispatch(setDeleteDialog({
            open: true, data: {
                id,
                title: `Delete Curriculum ${name}?`,
                content: "curriculums",
                body: `Before deleting this Curriculum, please note that by clicking the "Delete" button, all associated entities will be removed from the system. Are you sure you want to proceed?`,
                onDelete: async () => {
                    var res = await deleteCurriculum(+id)
                    queryClient.invalidateQueries("curriculums");
                    return res
                },
            },
        }));
        //
    };

    const [searchKey, setSearchKey] = React.useState<any>("");
    const debouncedSearchKey = useDebounce(searchKey, 500);
    const regionSetting = useAppSelector((store) => store?.school.regionSetting);
    const coloredRows = useAppSelector((store) => store?.curriculum.coloredRows);

    const {data: curriculums, isLoading, refetch,} = useSearchCurriculumQuery(debouncedName, {
        pageSize: 100, ...((typeof schoolId == "number" || typeof schoolId == "string") && {schoolId: schoolId}), ...(regionSetting?.id && {regionId: regionSetting?.id}), ...(regionId?.id && {regionId: regionId?.id}), ...(typeof regionId == "number" && {regionId: regionId}), ...(debouncedName && {search: debouncedName}), ...(pageSize && {pageSize: pageSize}), ...(page && {page: page + 1}), ...((programId || selectedProgram?.id > 0) && {programId: selectedProgram?.id || programId})
    }, selectedProgram);
    const [highlightedRows, setHighlightedRows] = useState<number[]>([]);

    useEffect(() => {
        const timeoutRefs: NodeJS.Timeout[] = [];
        const newHighlightedRows = curriculums?.data?.map((row, index) => {
            if (row?.curriculumId === coloredRows) {
                const timeoutRef = setTimeout(() => {
                    setHighlightedRows(prev => prev.filter(idx => idx !== index));
                }, 6000);
                timeoutRefs.push(timeoutRef);
                return index;
            }
            return -1;
        }).filter(index => index !== -1);

        setHighlightedRows(newHighlightedRows || []);
        dispatch(setColoredRows(-1))
        return () => {
            timeoutRefs.forEach(clearTimeout);
        };
    }, [curriculums?.data?.length, coloredRows]);
    const curriculumCount = useMemo(() => {
        return curriculums?.data?.length
    }, [])
    /*

      const {data: disciplinesCount} = useCountDisciplineQuery({
        ...(schoolId  && {schoolId: schoolId }),
      });
    const {data: programsCount} = useCountProgramQuery({
        ...(schoolId  && {schoolId: schoolId }),
      });
    */


    const {changePage, changeRowsPerPage} = usePager(setPage, setPageSize);


    const totalCount = useMemo(() => {
        return (curriculums?.totalCount)
    }, [curriculums?.totalCount])

    const totalRowPage = useMemo(() => {
        return (curriculums?.pageSize) ?? 10
    }, [curriculums?.pageSize])
    const type = useSelector((store: RootState) => store.auth?.user?.type)
    const roles = useSelector((store: RootState) => store.auth?.user?.roles)
    const classes = useStyles();
    const options: MUIDataTableOptions = {
        filterType: "checkbox",
        textLabels: {
            body: {
                noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
            }
        },
        setRowProps: (row, dataIndex) => {
            if (highlightedRows.includes(dataIndex)) {
                return {
                    className: classes.highlightedRow
                };
            } else {
                return {
                    className: classes.fadedRow
                };
            }
            return {};
        },
        serverSide: true,
        count: totalCount,
        rowsPerPage: totalRowPage, //selectableRowsOnClick: true,
        tableBodyMaxHeight: "600px",
        tableId: "curiculumtable",
        selectableRows: "none",
        print: false,
        download: type?.id != 1,
        onRowClick: (rowData, rowMeta) => {
            expandedRow(rowData, rowMeta);
        },
        onTableChange: (action, newTableState) => {
            switch (action) {
                case "changeRowsPerPage":
                    changeRowsPerPage(newTableState.rowsPerPage);
                    break;

                case "changePage":
                    changePage(newTableState.page, (newTableState.data?.length ?? 100));
                    break;
                case "search":
                    setName(newTableState.searchText);
                    setPage(0)
                    break;
            }
        },
        isRowExpandable: () => true, // expandableRows: true,
        // expandableRowsHeader: false,
        // renderExpandableRow: (rowData, rowMeta) => {
        //   const colSpan = rowData.length + 1;
        //   const data = curriculums?.data[rowMeta.rowIndex] as any;

        //   return (
        //     <>
        //       <TableRow>
        //         <TableCell
        //           colSpan={colSpan}
        //           sx={{
        //             px: 2,
        //           }}
        //         >
        //           <Typography variant="overline" fontWeight="bold">
        //             Courses:
        //           </Typography>
        //           {data?.curriculumSubjects?.map((curriculum: any) => {
        //             return (
        //               <Box>
        //                 <Button onClick={() => expandedRow(rowData, rowMeta)}>
        //                   {curriculum?.subject?.name}
        //                 </Button>
        //               </Box>
        //             );
        //           })}
        //         </TableCell>
        //       </TableRow>
        //     </>
        //   );
        // },
    };
    const handleDownloadCurriculumTemplate = React.useCallback(async () => {
        const res = await downloadExcelCurriculumLinkTemplate();
        onDownloadDocument(res.data, "Download Curriculum and Course Template");
    }, []);
    const handleDownloadCourseCurriculumTemplate = React.useCallback(async () => {
        const res = await downloadExcelCourseCurriculumLinkTemplate();
        onDownloadDocument(res.data, "Download Curriculum and Course Template");
    }, []);
    const queryClient = useQueryClient();

    const {
        handleOpen: handleOpenEditCurriculum, handleClose: handleCloseEditCurriculum, open: openEditCurriculum,
    } = useDialog();


    const columns: MUIDataTableColumn[] = [{
        name: "curriculumId", label: "ID", options: {
            display: false, filter: false, sort: false,
        },
    }, {
        name: "schoolId", label: "SCHOOL Id", options: {
            filter: true, sort: false, display: false,
        },
    }, {
        name: "schoolName", label: "SCHOOL NAME", options: {
            filter: true,
            sort: false,
            display: type?.id == 2 || type?.id == 3 || roles?.toLowerCase() == "superadmin",
            customBodyRender: (value: any, tableMeta, updateValue: any) => {
                const name = tableMeta.rowData[columns.findIndex((x) => x.name === "name")];
                return (<Typography fontSize={15} sx={{wordBreak: "break-word"}}>
                    {value}
                </Typography>);
            }
        },
    },

        {
            name: "levelName", label: "Level NAME", options: {
                filter: true, sort: false, display: false
            }
        }, {
            name: "programName", label: "PROGRAM NAME", options: {
                filter: true, sort: false, customBodyRender: (value: any, tableMeta, updateValue: any) => {

                    return (<Typography fontSize={15} sx={{wordBreak: "break-word"}}>
                        {value}
                    </Typography>);
                },
            }
        }, {
            name: "majorName", label: "MAJOR NAME", options: {
                filter: true, sort: false, customBodyRender: (value: any, tableMeta, updateValue: any) => {
                    const levelname = tableMeta.rowData[columns.findIndex((x) => x.name === "levelName")];
                    return (<Typography fontSize={15} sx={{wordBreak: "break-word"}}>
                        {value ? `${value}` : "-"}{levelname ? "/" + `${levelname}` : ""}
                    </Typography>);
                },
            }
        }, {
            name: "curriculumName", label: "CURRICULUM NAME", options: {
                filter: true, sort: false, customBodyRender: (value: any, tableMeta, updateValue: any) => {
                    const name = tableMeta.rowData[columns.findIndex((x) => x.name === "curriculumName")];
                    return (<Typography fontSize={15} sx={{textDecoration: "underline", wordBreak: "break-word"}}>
                            {value}
                            <Tooltip title="Copy to clipboard" arrow>
                                <IconButton
                                    // color="error"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        try {
                                            navigator.clipboard.writeText(name)
                                            dispatch(openSnackbar({
                                                open: true, message: "Copy To Clipboard", severity: "success",
                                            }));
                                        } catch (e) {

                                        }
                                    }}
                                >
                                    <CopyAllOutlined/>
                                </IconButton>
                            </Tooltip>
                        </Typography>


                    );
                },
            },
        }, {
            name: "curriculumId", label: "ACTION", options: {
                display: (type?.id == 1 || type?.id == 2) && ["Admin"].indexOf(roles) > -1,
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta, updateValue) => {
                    const _name = tableMeta.rowData[columns.findIndex((c) => c.name === "curriculumName")];

                    const _schoolId = tableMeta.rowData[columns.findIndex((c) => c.name === "schoolId")];

                    const _schoolName = tableMeta.rowData[columns.findIndex((c) => c.name === "schoolName")];


                    return (<Stack direction="row" gap={1}>
                        {/* <Tooltip title="Configure Courses" arrow>
                <IconButton
                  color="info"
                  size="small"
                  value={value}
                  onClick={(e) => {
                    e.stopPropagation();
                    alert("Configure Courses");
                  }}
                >
                  <PostAddRounded />
                </IconButton>
              </Tooltip> */}
                        {(type?.id == 2 || type?.id == 1) && ["Admin"].indexOf(roles) > -1 ? <Tooltip title="Edit" arrow>
                            <IconButton
                                color="primary"
                                size="small"
                                value={value}
                                onClick={(e) => {

                                    e.stopPropagation();
                                    dispatch(setEditCurriculum(value as number))
                                    dispatch(editCurriculimSchoolId(_schoolId))
                                    dispatch(editCurriculimSchoolName(_schoolName))
                                    if (curriculumCount) {
                                        dispatch(setCurriculumCount(curriculumCount))
                                    }
                                    handleOpenEditCurriculum();
                                }}
                            >
                                <EditRounded/>
                            </IconButton>
                        </Tooltip> : null}
                        {(type?.id == 1 || type?.id == 2) && ["Admin"].indexOf(roles) > -1 ?
                            <Tooltip title="Delete" arrow>
                                <IconButton
                                    color="error"
                                    size="small"
                                    value={value}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteCurriculum(value, _name);
                                    }}
                                >
                                    <DeleteRounded/>
                                </IconButton>
                            </Tooltip> : null}


                    </Stack>);
                },
            },
        },];
    const expandedRow = (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
        const name: any = rowData[columns.findIndex((x) => x.name === "curriculumName")];
        const curriculumId: any = rowData[columns.findIndex((x) => x.name === "curriculumId")];
        const curriculumIdObject = curriculums?.data?.find((c: any) => c?.curriculumId == curriculumId)

        dispatch(setTabValue("2"));
        dispatch(setCurriculumId(rowData[0]));
        dispatch(setCurriculumBreadcrumb(name?.props?.children[0] ?? name));
        dispatch(setCurriculum(curriculumIdObject ?? name));
    };

    const curriculumTableTabOpen = useAppSelector((store) => store?.school.curriculumTableTabOpen);

    function handleCloseAddCurriculum() {
        dispatch(setCurriculumTabState(false));
    }

    function handleOpenAddCurriculum() {
        dispatch(setCurriculumTabState(true));
    }


    const getMuiTheme = usePointerTheme();
    return (<>
        <MUIDataTable
            title={title}
            data={curriculums?.data as any}
            columns={columns}
            options={options}
            components={{
                TableFilterList: (props) => <TableFilterList {...props} />,
                TableToolbar: useCallback((props: MUIDataTableToolbar) => {
                    return (<>
                        <TableToolbar {...props} />
                        <Box
                            sx={{
                                display: "flex", alignItems: "center", justifyContent: "flex-end", mx: 3, my: 1,
                            }}
                        >
                            {type?.id == 1 && ["Admin"].indexOf(roles) > -1 && type?.id == 1 ?
                                <Stack gap={1} direction="row">
                                    <Button

                                        startIcon={<AddRounded/>}
                                        variant="contained"
                                        onClick={handleOpenAddCurriculum}
                                    >
                                        Add Curriculum
                                    </Button>
                                    <UploadCourseCurriculumDialog/>
                                    <Button
                                        startIcon={<DownloadRounded/>}
                                        variant="contained"
                                        onClick={handleDownloadCourseCurriculumTemplate}
                                    >
                                        Download Curriculum and Course
                                    </Button>
                                </Stack> : null}
                        </Box>
                    </>);
                }, []),
                icons: {
                    FilterIcon: FilterAltRounded as unknown as ReactNode,
                    ViewColumnIcon: ViewColumnRounded as unknown as ReactNode,
                },
            }}
        />

        <AddCurriculum
            setting={setting}
            open={curriculumTableTabOpen}
            handleClose={handleCloseAddCurriculum}
        />

        <EditCurriculum
            setting={setting}
            open={openEditCurriculum}
            handleClose={handleCloseEditCurriculum}
        />
    </>);
};

export default CurriculumTable;
