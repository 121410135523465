import { AlertColor } from "@mui/material";
// create slice for snackbar

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISnackbarSlice {
  open: boolean;
  message: string;
  severity: AlertColor;
  position?: "left" | "center" | "right";
}

const initialState: ISnackbarSlice = {
  open: false,
  message: "",
  position: "center",
  severity: "success",
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<ISnackbarSlice>) => {
      state = action.payload;
      return state;
    },
    closeSnackbar: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
