import {
    AddRounded,
    CopyAllOutlined,
    DeleteRounded,
    DownloadRounded,
    EditRounded,
    FilterAltRounded,
    ViewColumnRounded,
} from "@mui/icons-material";
import {Box, Button, IconButton, Stack, TableCell, TableRow, Tooltip, Typography,} from "@mui/material";
import moment from 'moment-timezone';
import MUIDataTable, {
    MUIDataTableColumn,
    MUIDataTableOptions,
    MUIDataTableToolbar,
    TableFilterList,
    TableToolbar,
} from "mui-datatables";
import {AxiosError} from "axios";
import React, {FC, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {useCourseQuery} from "../../hooks/useCourseQuery";
import {useDispatch, useSelector} from "react-redux";
import {deleteCourse} from "../../requests/course.request";
import AddCourse from "../dialogs/AddCourse.dialog";
import {RootState, useAppSelector} from "../../redux/store";
import {
    setCourseBreadcrumb,
    setCourseId,
    setCourseTabState,
    setPrerequisiteId,
    setPrerequisiteName,
} from "../../redux/features/school";
import {onDownloadDocument} from "../../helper/utils/helper";
import UploadCourseDialog from "../dialogs/UploadCourse.dialog";
import {downloadExcelCourseLinkTemplate} from "../../requests/download.request";
import {setDeleteDialog, setLoading} from "../../redux/features/app";
import {useQueryClient} from "react-query";
import {usePager} from "../../hooks/usePager";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import useDialog from "../../hooks/useDialog";
import AddPrerequisiteDialog from "../dialogs/AddPrerequisite.dialog";
import {useDebounce} from "../../hooks/useDebounce";
import {useAddEntities} from "../../hooks/useAddEntities";
import {openSnackbar} from "../../redux/features/snackbar";
import EditCourse from "../dialogs/EditCourse.dialog";
import {editCourseSchoolId, editCourseSchoolName, setEditCourse} from "../../redux/features/course";
import {useStyles} from "../../styles/fade";

interface ICourseTableProps {
    title: string;
    setting?: boolean;
}


const CourseTable: FC<ICourseTableProps> = (props) => {
    const dispatch = useDispatch();
    const {schoolId, regionId} = useAddEntities();
    const curriculumId = useAppSelector((store) => store?.school?.curriculumId);
    const queryClient = useQueryClient();


    const {
        handleOpen: handleAddPrerequisite, handleClose: handleClosePrerequisite, open: openPrerequisite,
    } = useDialog();


    const {
        handleOpen: handleEditCourseOpen, handleClose: handleEditCourseClose, open: openEditCourse,
    } = useDialog();


    const [page, setPage] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const [searchKey, setSearchKey] = React.useState<any>("");
    const debouncedSearchKey = useDebounce(searchKey, 500);
    const {title} = props;
    const regionSetting = useAppSelector((store) => store?.school.regionSetting);
    //const { schoolId } = useParams() as { schoolId: string };
    const {data: coursesData, isLoading, refetch} = useCourseQuery({
        pageSize: 100,
        schoolId,
        flatten: true, ...(regionSetting?.id && {regionId: regionSetting?.id}), ...(regionId?.id && {regionId: regionId?.id}), ...(typeof regionId == "number" && {regionId: regionId}), ...(debouncedSearchKey && {search: debouncedSearchKey}), ...(page && {page: page + 1}), ...(pageSize && {pageSize: pageSize}), ...(curriculumId && {curriculumId: curriculumId}),
    });
    const [highlightedRows, setHighlightedRows] = useState<number[]>([]);
    const coloredRows = useAppSelector((store) => store?.curriculum.coloredRows);


    const totalRowPage = useMemo(() => {
        return (coursesData?.pageSize)
    }, [coursesData?.pageSize])
    const totalCount = useMemo(() => {
        return (coursesData?.totalCount)
    }, [coursesData?.totalCount])
    const handleRowClick = (rowData: any, rowMeta: any) => {
        const name = rowData[columns.findIndex((x) => x.name === "name")];
        dispatch(setCourseId(rowData[0]));
        dispatch(setCourseBreadcrumb(name?.props?.children?.[0] ?? name));
    };

    const handleDownloadExcelTemplate = async () => {
        try {
            dispatch(setLoading(true));
            const res = await downloadExcelCourseLinkTemplate();

            onDownloadDocument(res.data, "Course Template");
        } catch (error) {
            let errorMsg = "Something went wrong while downloading the file";
            if (error instanceof AxiosError) {
                errorMsg = error?.response?.data?.message;
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleDeleteCourse = (id: string | number, name: string) => {
        dispatch(setDeleteDialog({
            open: true, data: {
                id,
                title: `Do you want to delete this course?`,
                content: `courses`,
                body: `Course : ${name}`,
                onDelete: async () => {
                    var course = await deleteCourse(+id);
                    // queryClient.invalidateQueries("courses");
                    return course
                },
            },
        }));
    };

    const handlePrerequisiteCourse = (id: string | number, name: string) => {
        dispatch(setPrerequisiteName(name));
        dispatch(setPrerequisiteId(id));
        handleAddPrerequisite()
    };
    const roles = useSelector((store: RootState) => store.auth?.user?.roles)
    const type = useSelector((store: RootState) => store.auth?.user?.type)
    const columns: MUIDataTableColumn[] = [{
        name: "id", label: "ID", options: {
            display: false, filter: false, sort: false,
        },
    }, {
        name: "schoolName", label: "SCHOOL NAME", options: {
            filter: true,
            sort: false,
            display: type?.id == 2 || type?.id == 3 || roles?.toLowerCase() == "superadmin",
            customBodyRender: (value: any, tableMeta, updateValue: any) => {
                const name = tableMeta.rowData[columns.findIndex((x) => x.name === "name")];
                return (<Typography fontSize={15} sx={{wordBreak: "break-word"}}>
                    {value}
                </Typography>);
            }
        },
    }, {
        name: "schoolId", label: "SCHOOL Id", options: {
            filter: true, sort: false, display: false,

        },
    }, {
        name: "curriculumName", label: "CURRICULUM NAME", options: {
            filter: true, sort: false,
        },
    },{
        name: "program", label: "PROGRAM NAME", options: {
            filter: true, sort: false,
        },
    },{
        name: "major", label: "MAJOR NAME", options: {
            filter: true, sort: false,
        },
    }, {
        name: "courseCode", label: "COURSE CODE", options: {
            filter: true, sort: false, customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
                const name = tableMeta.rowData[columns.findIndex((x) => x.name === "courseCode")];
                return <Typography fontSize={15} sx={{textDecoration: "underline", wordBreak: "break-word"}}>
                    {value}
                    <IconButton
                        //color="error"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();

                            try {
                                navigator.clipboard.writeText(name)
                                dispatch(openSnackbar({
                                    open: true, message: "Copy To Clipboard", severity: "success",
                                }));
                            } catch (e) {

                            }
                        }}
                    >
                        <CopyAllOutlined/>
                    </IconButton>
                </Typography>
            }
        },
    }, {
        name: "courseName", label: "Name of Course".toUpperCase(), options: {
            filter: true, sort: false, customBodyRender: (value: any, tableMeta, updateValue: any) => {
                const name = tableMeta.rowData[columns.findIndex((x) => x.name === "courseName")];
                return (<Typography fontSize={15} sx={{textDecoration: "underline", wordBreak: "break-word"}}>
                    {value}
                    <IconButton
                        //color="error"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();

                            try {
                                navigator.clipboard.writeText(name)
                                dispatch(openSnackbar({
                                    open: true, message: "Copy To Clipboard", severity: "success",
                                }));
                            } catch (e) {

                            }
                        }}
                    >
                        <CopyAllOutlined/>
                    </IconButton>
                </Typography>);
            },
        },
    },

        {
            name: "courseDescription", label: "COURSE DESCRIPTION", options: {
                filter: true, sort: false, customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
                    const name = tableMeta.rowData[columns.findIndex((x) => x.name === "courseDescription")];
                    return <Typography fontSize={15} sx={{textDecoration: "underline", wordBreak: "break-word"}}>
                        {value}
                        <IconButton
                            //color="error"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();

                                try {
                                    navigator.clipboard.writeText(name)
                                    dispatch(openSnackbar({
                                        open: true, message: "Copy To Clipboard", severity: "success",
                                    }));
                                } catch (e) {

                                }
                            }}
                        >
                            <CopyAllOutlined/>
                        </IconButton>
                    </Typography>
                }
            },
        }, {
            name: "courseSemester", label: "SEMESTER", options: {
                filter: true, sort: false, customBodyRender: (value) => {
                    const mappedSemester = {
                        1: "1st Semester", 2: "2nd Semester", 3: "Midyear Semester  (Summer)", null: "-",
                    };
                    return mappedSemester[value as keyof typeof mappedSemester];
                },
            },
        }, {
            name: "courseYearLevel", label: "YEAR LEVEL", options: {
                filter: true, sort: false, customBodyRender: (value) => {
                    const mappedYearLevel = {
                        1: "1st Year", 2: "2nd Year", 3: "3rd Year", 4: "4th Year", 5: "5th Year", null: "-",
                    };
                    return mappedYearLevel[value as keyof typeof mappedYearLevel];
                },
            },
        },


        {
            name: "dateCreated", label: "DATE CREATED", options: {
                display: false, filter: false, sort: false, customBodyRenderLite: (value: any) => {
                    return moment(value).format("lll");
                },
            },
        }, {
            name: "lastModifiedDate", label: "DATE UPDATED", options: {
                display: false, filter: false, sort: false, customBodyRenderLite: (value: any) => {
                    return moment(value).format("lll");
                },
            },
        }, {
            name: "laboratory", label: "LAB CREDIT", options: {
                display: false, filter: false, sort: false,
            },
        }, {
            name: "lecture", label: "LEC CREDIT", options: {
                display: false, filter: false, sort: false,
            },
        },

        {
            name: "totalUnits", label: "TOTAL UNITS", options: {
                display: false, filter: false, sort: false, customBodyRender: (value: any, tableMeta, updateValue) => {
                    const laboratory = tableMeta.rowData[columns.findIndex((x) => x.name === "laboratory")];
                    const lecture = tableMeta.rowData[columns.findIndex((x) => x.name === "lecture")];
                    const totalCredit = +laboratory + +lecture;
                    return totalCredit;
                },
            },
        }, {
            name: "id", label: "ACTION", options: {
                filter: false,
                sort: false,
                display: (type?.id == 1 || type?.id == 2) && ["Admin"].indexOf(roles) > -1,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta, updateValue: any) => {
                    const name = tableMeta.rowData[columns.findIndex((x) => x.name === "curriculumName")];
                    const courseName = tableMeta.rowData[columns.findIndex((x) => x.name === "courseName")];

                    const _curriculumId = tableMeta.rowData[columns.findIndex((x) => x.name === "curriculumId")];
                    const _schoolId = tableMeta.rowData[columns.findIndex((x) => x.name === "schoolId")];

                    const _schoolName = tableMeta.rowData[columns.findIndex((x) => x.name === "schoolName")];


                    return (<Stack direction="row" spacing={1}>

                        {type?.id == 1 ? <Tooltip title="Prerequisite" arrow>
                            <IconButton
                                color="primary"
                                size="small"
                                value={value}
                                onClick={() => handlePrerequisiteCourse(value, name)}
                            >
                                <DescriptionOutlinedIcon/>
                            </IconButton>
                        </Tooltip> : null}
                        {(type?.id == 2 || type?.id == 1) && ["Admin"].indexOf(roles) > -1 ?
                            <Tooltip title="Edit" arrow>
                                <IconButton
                                    color="primary"
                                    size="small"
                                    value={value}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(editCourseSchoolId(_schoolId))
                                        dispatch(editCourseSchoolName(_schoolName))
                                        dispatch(setEditCourse({
                                            editCoursesId: value as number, editCurriculumId: _curriculumId
                                        }))
                                            handleEditCourseOpen()
                                        }}
                                    >
                                        <EditRounded/>
                                    </IconButton>
                                </Tooltip> : null}
                            {["Admin"].indexOf(roles) > -1 && (type?.id == 2 || type?.id == 1) ?
                                <Tooltip title="Delete" arrow>
                                    <IconButton
                                        color="error"
                                        size="small"
                                        value={value}
                                        onClick={() => handleDeleteCourse(value, courseName)}
                                    >
                                        <DeleteRounded/>
                                    </IconButton>
                                </Tooltip> : null}
                    </Stack>);
                },
            },

        }, {
            name: "curriculumId", label: "curriculum id", options: {
                display: false, filter: false, sort: false,
            },
        },];


    const {changePage, changeRowsPerPage} = usePager(setPage, setPageSize);

    function renderCourseExpandable(rowData: string[]) {
        const id = rowData[columns.findIndex((x) => x.name === "id")];
        const labUnit = rowData[columns.findIndex((x) => x.name === "laboratory")];
        const lecUnit = rowData[columns.findIndex((x) => x.name === "lecture")];
        const prerequisiteCode = coursesData?.data.find(c => c.id.toString() === id.toString())
        return (<TableRow
            sx={{
                backgroundColor: "grey",
            }}
        >
            <TableCell
                sx={{paddingBottom: 0, paddingTop: 0, color: "white"}}
                colSpan={rowData.length}
            >
                <Stack direction="row" gap={10}>
                    <Typography variant="overline">
                            Lecture: <strong>{lecUnit}</strong>
                        </Typography>
                        <Typography variant="overline">
                            Laboratory: <strong>{labUnit}</strong>
                        </Typography>
                    <Typography variant="overline">
                        Total Credits:{" "}
                        <strong>{+(lecUnit ?? 0) + +(labUnit ?? 0)}</strong>
                    </Typography>
                    <Typography variant="overline">
                        Prerequisite:{" "}
                        <strong>{prerequisiteCode?.prerequisiteCourseCodes.toString() != "" ? prerequisiteCode?.prerequisiteCourseCodes?.toString() : "none"}</strong>
                    </Typography>
                </Stack>
            </TableCell>
        </TableRow>);
    }

    const classes = useStyles();
    const options: MUIDataTableOptions = {
        print: false,
        textLabels: {
            body: {
                noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
            }
        },
        setRowProps: (row, dataIndex) => {
            if (highlightedRows.includes(dataIndex)) {
                return {
                    className: classes.highlightedRow
                };
            } else {
                return {
                    className: classes.fadedRow
                };
            }
            return {};
        },
        download: type?.id != 1,
        filter: false,
        serverSide: true,
        count: totalCount,
        tableId: "coursetable",
        rowsPerPage: totalRowPage ?? pageSize,
        page: Math.min(typeof totalCount === "number" ? totalCount : 10, page),
        onTableChange: (action, newTableState) => {
            switch (action) {
                case "search":
                    setPage(0)
                    setSearchKey(newTableState.searchText);
                    break;
                case "onSearchClose":
                    setSearchKey("");
                    break;
                case "changeRowsPerPage":
                    changeRowsPerPage(newTableState.rowsPerPage);
                    break;
                case "changePage":
                    changePage(newTableState.page, (newTableState.data?.length ?? 100));
                    break;
            }
        }, //selectableRowsOnClick: true,
        onRowClick: handleRowClick,
        selectableRows: "none",
        isRowExpandable: () => true,
        expandableRows: true,
        expandableRowsHeader: true,
        renderExpandableRow: (rowData, rowMeta) => {
            return renderCourseExpandable(rowData);
        },
    };

    const courseTabOpen = useAppSelector((store) => store?.school.courseTableTabOpen);

    function handleCloseAddCourse() {
        dispatch(setCourseTabState(false));
    }

    function handleOpenAddCourse() {
        dispatch(setCourseTabState(true));
    }

    useEffect(() => {

        refetch();
    }, [schoolId, curriculumId, courseTabOpen, openPrerequisite]);

    return (<>
        <div style={{display: 'table', tableLayout: 'fixed', width: '100%'}}>

            <MUIDataTable
                title={title}
                data={coursesData?.data ?? ([] as any)}
                columns={columns}
                options={options}

                components={{
                    TableFilterList: (props) => {
                        return <TableFilterList {...props} />;
                    }, TableToolbar: useCallback((props: MUIDataTableToolbar) => {
                        return (<>
                            <TableToolbar {...props} />
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    mx: 3,
                                    my: 1,
                                }}
                            >
                                        <Stack direction="row" gap={1}>

                                        </Stack>

                                        {type?.id == 1 && ["Admin"].indexOf(roles) > -1 ?
                                            <Stack spacing={1} direction="row">
                                                <Button
                                                    startIcon={<AddRounded/>}
                                                    variant="contained"
                                                    onClick={handleOpenAddCourse}
                                                >
                                                    Add Course
                                                </Button>
                                            </Stack> : null}
                            </Box>
                        </>);
                    }, []), icons: {
                        FilterIcon: FilterAltRounded as unknown as ReactNode,
                        ViewColumnIcon: ViewColumnRounded as unknown as ReactNode,
                    },
                }}
                />
            </div>
            <AddPrerequisiteDialog

                open={openPrerequisite}
                handleClose={handleClosePrerequisite}
            />
        <EditCourse

            open={openEditCourse}
            handleClose={handleEditCourseClose}
        />
        {courseTabOpen ?
            <AddCourse setting={props?.setting} open={courseTabOpen} handleClose={handleCloseAddCourse}/>: null
        }

    </>);
};

export default CourseTable;
