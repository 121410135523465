// create route wrapper

import {FC} from 'react';
import {Route} from 'react-router-dom';
import Private from '../components/Private';
import LoginPage from '../pages/Login.page';
import SignupPage from '../pages/Signup.page';
import ForgotPasswordPage from "../pages/ForgotPassword.page";
import ResetPasswordPage from "../pages/ResetPassword.page";

export const RoutesWrapper: FC = () => {

    return (
        <>
            <Route path='/reset-password' component={ResetPasswordPage} exact/>
            <Route path='/login' component={LoginPage} exact/>
            <Route path='/forgot-password' component={ForgotPasswordPage} exact/>
            <Route path='/sign-up' component={SignupPage} exact/>
            <Private />
        </>
    )
}
