import {AddRounded, SearchRounded,} from "@mui/icons-material";
import {
    Autocomplete,
    Avatar,
    Box,
    Container,
    Divider, FormControl,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText, MenuItem,
    Paper, Select, SelectChangeEvent,
    TableFooter,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {useQueryClient} from "react-query";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import useAddStudentDialog from "../components/dialogs/AddStudent.dialog";
import {useStudentQuery} from "../hooks/useStudentQuery";
import {ISnackbarSlice, openSnackbar} from "../redux/features/snackbar";
import {deleteSchool} from "../requests/school.request";
import {RootState, useAppSelector} from "../redux/store";
import {useDebounce} from "../hooks/useDebounce";
import {TablePaginationActions} from "../components/Table/TablePaginationAction";
import {useSchoolQuery} from "../hooks/useSchoolQuery";
import {useProgramQuery} from "../hooks/useProgramQuery";
import {yearLevelFormat} from "../helper/utils/helper";
import {useRegionSelectQuery} from "../hooks/useRegionQuery";
import {useAddEntities} from "../hooks/useAddEntities";
import _ from "lodash";
import IsLoading from "../components/Searching";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import {setClear, setSelectedSettingRegion, setTabValue} from "../redux/features/school";
import {useLocation} from "react-router";


const StudentListPage = () => {
    const {schoolId} = useParams() as { schoolId: string }
    const type = useSelector((store: RootState) => store.auth?.user?.type)
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [searchStr, setSearchStr] = React.useState("");
    const [searchProgram, setSearchProgram] = React.useState("");
    const [searchYearLevel, setSearchYearLevel] = React.useState("");
    const debouncedSearchStr = useDebounce(searchStr, 500);
    const debouncedSearchProgram = useDebounce(searchProgram, 500);
    const [selectedSchool, setSelectedSchool] = React.useState<any>({name: "All", id: undefined});
    const [selectedProgram, setSelectedProgram] = React.useState<any>({name: "All", id: undefined});
    const [selectedYearLevel, setSelectedYearLevel] = React.useState<any>({name: "All", id: undefined});
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [selectedRegionId, setSelectedRegionId] = React.useState<any>({
        id: 0,
        name: "All"
    });
    const [selectedStatus, setSelectedStatus] = React.useState<any>(null);
    const {regionId} = useAddEntities();
    const mappedYearLevel = [
        {id: 1, name: "1st Year"},
        {id: 2, name: "2nd Year"},
        {id: 3, name: "3rd Year"},
    {id:4, name: "4th Year"},
    {id: 5,name:  "5th Year"},
        {id:null ,name:  "-"},
  ];

  const {data: regions} = useRegionSelectQuery()
  const {data: students, refetch, isLoading, isFetching} = useStudentQuery({
    pageSize: "100",

    search: debouncedSearchStr,

    //...schoolId && {schoolId: schoolId},
    isGraduated: selectedStatus,
    ...selectedRegionId?.id > 0 && {regionId: selectedRegionId?.id},
    ...(selectedYearLevel?.id > 0 && {yearLevel: selectedYearLevel?.id}),
    ...((selectedSchool?.id > 0 || schoolId) && {schoolId: selectedSchool?.id || schoolId}),
    ...(selectedProgram?.name ? selectedProgram?.name != "All" && selectedProgram && {programName: selectedProgram?.name} : null),
    ...(page && {page: page + 1}),
  });
  const [selectedStudentId, setSelectedStudentId] = React.useState<
    number | null
  >(null);
  const {
    Component: AddStudent,
    handleClickOpen: handleClickOpenAddStudent,
    handleClose: handleCloseAddStudent,
  } = useAddStudentDialog();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const addStudentCount = useAppSelector(
    (store) => store?.student?.addStudentCount
  );

  const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);

  const handleViewStudent = useCallback(
    ({ studentId, title }: { studentId: number; title: string }) => {
      history.push(`/students/${studentId}`, {
        studentId,
        from: "/students",
        title,
      });
    },
    []
  );
    const navigate = useHistory();
    const location = useLocation();
  const handleViewStudentEvaluation = useCallback(
    ({ studentId, title }: { studentId: number; title: string }) => {
      history.push(`/students/${studentId}/evaluation`, {
        studentId,
        from: "/students",
        title,
      });
    },
    []
  );

  const handleDelete = async () => {
    if (selectedStudentId) {
      try {
        await deleteSchool(selectedStudentId);
        setSnackbarStore({
          message: "Student deleted successfully",
          severity: "success",
          open: true,
        });
        queryClient.setQueriesData("student", (oldData: any) => {
          return {
            ...oldData,
            data: oldData.data.filter(
              (school: any) => school.id !== selectedStudentId
            ),
          };
        });
      } catch (error: any) {
        console.error({ error });
        const errorMsg =
          error.message || "Something went wrong while deleting student data";
        setSnackbarStore({
          message: errorMsg,
          severity: "error",
          open: true,
        });
      }
    }
  };
  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (students?.data ?? []).length) : 0;

  const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
  ) => {
    setPage(newPage);
  };
    const handleChange = async (event: SelectChangeEvent) => {
        setSelectedStatus(event.target.value);
        navigate.replace(`${location.pathname}?status=${event.target.value}`, { replace: true });
    };


  const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    refetch();
  }, [addStudentCount]);
  const roles = useAppSelector((store) => store?.auth?.user?.roles) as
      | "User"
      | "Admin"
      | "SuperAdmin";

  const {data: dataSchools, isLoading: studentLoading, isFetching: studentIsFetching} = useSchoolQuery({
    pageSize: "1000",
    search: '',
    ...(selectedRegionId?.id != 0 && {regionId: selectedRegionId.id}),
    ...(type?.id == 2 && roles == "User") && {regionId: regionId}
  });

  const { data: dataPrograms, isLoading: programLoading } = useProgramQuery({
    pageSize: "100",
    select: true,

    ...(selectedSchool?.id > 0 && {schoolId:selectedSchool?.id}),
    ...(debouncedSearchProgram && {search: debouncedSearchProgram}),
  });


    useEffect(() => {
        var query = new URLSearchParams(location.search)
        const page = query.get('status') || '';
        setSelectedStatus(page)
    }, [location.search]);
    const [statusOption, setStatusOption] = useState([
        { title: 'All', value: "" },
        { title: 'Graduated', value: "complete" },
        { title: 'Incomplete', value: "incomplete" },
    ])
  return (
    <>
      <Helmet>
        <title>Student List</title>
      </Helmet>

      <Box
        component="main"
        sx={{ flexGrow: 1, pt: 10 }}
        style={{ paddingBottom: "20px",  paddingLeft: " 20px", paddingRight: "20px" }}
      >
        <Container>
          <Paper>
            <List
              dense
              subheader={
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <Box>
                      <Typography variant="h5" fontWeight="bold">
                        Student List
                      </Typography>
                      {/* <Typography variant="body1">Lorem ipsum dolor sit amet consectetur adipisicing
                                            elit. Dignissimos, dolor. Rerum expedita praesentium animi
                                            error.</Typography>*/}
                    </Box>



                  </Box>
                  <Divider />
                  <Box p={2} flexWrap={"wrap"} display="flex" alignItems="center" gap={2}>

                    {((roles?.toLowerCase() == "superadmin" || type?.id == 3) && roles?.toLowerCase() != "user") && !schoolId ?
                        <form onSubmit={(event) => event.preventDefault()}>
                          <>
                            <Autocomplete id={"studentdashboardschoolyear"}
                                          disabled={isLoading}
                                          disableClearable
                                          size="small"
                                          value={selectedRegionId}
                                          onChange={(event: any, newValue: string | null) => {
                                            setSelectedRegionId(newValue ? newValue : {});
                                          }}
                                          getOptionLabel={(option) => {
                              return option.name
                            }
                            }
                                          renderOption={(props, option) => (
                                              <li {...props} id={"regionselvysetting" + option.id} key={option.id}>
                                                  <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                      {option.name}</div>
                                              </li>
                                          )}
                                          options={[{name: "All", id: undefined}, ...(regions || [])] || []}
                                          style={{width: 200, marginRight: 6,}}

                                          renderInput={(params) => (
                                <TextField {...params} label="Select Region" />
                            )}
                        />
                      </>
                    </form>: null}
                    { (type?.id === 2 || roles?.toLowerCase() == "superadmin") && !schoolId?  <form onSubmit={(event) => event.preventDefault()}>
                      <>
                        <div >
                          <Autocomplete id={"schoolstudentdashboardschoolyear"}
                                        style={{width: 200}}
                                        disabled={isLoading}
                                        disableClearable
                                        size="small"
                                        value={selectedSchool}
                                        onChange={(event: any, newValue: string | null) => {
                                setSelectedSchool(newValue ? newValue : {});
                              }}
                                        getOptionLabel={(option) => {
                                return option.name
                              }
                              }
                                        renderOption={(props, option) => (
                                            <li {...props} id={"selectstudentlistselevtsetting" + option.id}
                                                key={option.id}>
                                                <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                    {option.name ?? option.displayName}
                                                    <p style={{
                                                        fontStyle: "italic",
                                                        margin: 0,
                                                        fontSize: 12
                                                    }}>{option?.institutionalCode ? option.institutionalCode + " - " : ""}{option?.address ? option.address : (option?.address ? option.address + " - " : "") + (option?.province?.name ? option.province.name + " - " : "") + (option?.region?.name ? option.region.name + "" : "")}</p>
                                                </div>
                                            </li>
                                        )}
                                        options={[{name: "All", id: undefined}, ...(dataSchools?.data || [])] || []}
                                        sx={{width: 500}}
                                        renderInput={(params) => (
                                  <TextField {...params} id={"studentlistheiselect"} label="Select HEI Profile" />
                              )}
                          />
                        </div>

                      </>
                    </form> : null}

                    <form onSubmit={(event) => event.preventDefault()}>
                      <>
                        <div >
                          <Autocomplete id={"studentliststudentdashboardschoolyear"}
                                        style={{width: 200}}
                                        disabled={isLoading}
                                        disableClearable
                                        size="small"
                                        value={selectedProgram}
                                        onChange={(event: any, newValue: string | null) => {


                                          setSelectedProgram(newValue ? newValue : {});
                                        }}
                                        getOptionLabel={(option) => {
                                          return option.name
                                        }
                                        }
                                        renderOption={(props, option, index) => (
                                            <li {...props} key={option.name + "-" + option.id + "" + props.id}>
                                              <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                {option.name}

                                              </div>
                                            </li>
                                        )}
                                        options={[{
                                          name: "All",
                                          id: 0
                                        }, ...(_.uniqWith(dataPrograms?.data?.map((c: any, index: number) => {
                                          return c
                                        }), function (arrVal: any, othVal: any) {
                                          return arrVal?.id === othVal?.id;
                                        }) || [])] || []}
                                        sx={{width: 500}}
                                        renderInput={(params) => (
                                            <TextField  {...params} label="Select Program"/>
                                        )}
                          />
                        </div>

                      </>
                    </form>

                    <form onSubmit={(event) => event.preventDefault()}>
                      <>
                        <div >
                          <Autocomplete id={"studentschoolyearstudentdashboardschoolyear"}
                                        style={{width: 200}}
                                        disabled={isLoading}
                                        disableClearable
                                        size="small"
                                        value={selectedYearLevel}
                                        onChange={(event: any, newValue: string | null) => {
                                setSelectedYearLevel(newValue ? newValue : {});
                              }}
                                        getOptionLabel={(option) => {
                                return option.name
                              }
                              }
                                        renderOption={(props, option, index) => (
                                            <li {...props} key={option.name + "-" + option.id + "" + props.id}>
                                                <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                    {option.name}

                                                </div>
                                            </li>
                                        )}
                                        options={[{name: "All", id: undefined}, ...(mappedYearLevel || [])] || []}
                                        sx={{width: 500}}
                                        renderInput={(params) => (
                                  <TextField  {...params}  onChange={(event) => {
                                    setSearchYearLevel(event.target.value)
                                  }}  label="Select Year Level" />
                              )}
                          />
                        </div>

                      </>
                    </form>
                    <form
                        onSubmit={(event) => event.preventDefault()}
                        autoComplete={"new-password"}
                    >
                      <TextField
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                  <SearchRounded />
                                </InputAdornment>
                            ),
                          }}
                          id={"new-password"}
                          placeholder="Search Student"
                          sx={{}}
                          label="Search Student"
                          margin="dense"
                          size="small"
                          value={searchStr}
                          onChange={(e) => setSearchStr(e.target.value)}
                      />
                    </form>
                      <Autocomplete
                          size="small"
                          value={selectedStatus}
                          onChange={(event: any, newValue: { value: string, title: string }) => {
                              if(newValue?.value) {
                                  setSelectedStatus(newValue?.value);
                                  navigate.replace(`${location.pathname}?status=${newValue.value}`, { replace: true });
                              }else {
                                  setSelectedStatus("");
                                  navigate.replace(`${location.pathname}`, { replace: true });
                              }
                          }}

                          getOptionLabel={(option) => {
                              var statusOptionIndex = statusOption.findIndex(c => c.value == option)
                              if(statusOptionIndex > -1) {
                                  return statusOption[statusOptionIndex]?.title
                              }else {
                                  return statusOption[0]?.title ?? "All"
                              }

                              return "All"

                          }}
                          id="controllable-states-demo"
                          renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.title}
                              </Box>
                          )}
                          options={statusOption}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="Status" />}
                      />
                      {type?.id == 1 ?
                          <div style={{textAlign: 'right'}}>
                              <Button
                                  variant="contained"
                                  startIcon={<AddRounded/>}
                                  onClick={handleClickOpenAddStudent}
                              >
                                  Add Student
                              </Button>
                          </div> : null
                      }
                  </Box>
                    <Divider/>
                </>
              }
            >
                <Box
                    sx={{
                        overflowY: "scroll",
                  height: "calc(100vh - 200px)",
                }}
              >
                {!isFetching && students?.data?.map((student) => {
                  return (
                    <React.Fragment key={student.id}>
                      <ListItem
                        disablePadding
                        secondaryAction={
                          <Box
                            sx={{
                              display: "flex",
                              gap: 2,
                            }}
                          >
                            {/* {status[student.status as keyof typeof status]} */}
                            {/* <IconButton
                              onClick={handleViewStudentEvaluation.bind(null, {
                                studentId: student.id,
                                title: `${student.firstName ?? ""} ${
                                  student.middleName ?? ""
                                } ${student.lastName ?? ""}`,
                              })}
                              value={student.id}
                            >
                              <VisibilityRounded />
                            </IconButton> */}
                            {/* <IconButton onClick={onRowClick} value={student.id}>
                                                            <MoreHoriz/>
                                                        </IconButton>*/}
                          </Box>
                        }
                      >
                        <ListItemButton
                          onClick={handleViewStudent.bind(null, {
                            studentId: student.id,
                            title: `${student.firstName ?? ""} ${
                              student.middleName ?? ""
                            } ${student.lastName ?? ""}`,
                          })}
                        >
                          <ListItemAvatar>
                            <Avatar />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography variant="inherit" fontWeight="bold">
                                {student.firstName} {student.middleName}{" "}
                                {student.lastName}
                              </Typography>
                            }
                            secondary={<div>
                              {type?.id == 2 && student.school ?  <Typography variant="inherit">
                                School Name: <strong>{student.school}</strong>
                              </Typography> : null}
                              <div style={{flex: 1, flexDirection: "row", display: "flex", }}>
                              {student.yearLevel ?  <Typography variant="inherit">
                               Year Level: <strong>{yearLevelFormat(student.yearLevel)}&nbsp; | &nbsp;</strong>
                              </Typography> : null}

                              <Typography variant="inherit">
                                {student.studentNumber}&nbsp; | &nbsp;
                              </Typography>

                              <Typography variant="inherit">
                              Academic Status: <strong>
                                <label style={{color:student?.isGraduated ? "#2e7d32" : "#d32f2f"}} >
                                  {student?.isGraduated ? "Completed" : "Incomplete"}
                                </label>
                              </strong>
                              </Typography>
                            </div>
                            </div>}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })}


                {(students?.data?.length === 0 || !students) && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p={3}
                  >
                    <div style={{flex: 1, display: "flex", flexDirection: "column",  justifyContent: "center", alignItems: "center"}}>
                      <IsLoading loading={studentLoading || isLoading} fetching={isFetching || studentIsFetching}/>
                      <Typography variant="body1">
                        {studentLoading || isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."}
                      </Typography>
                    </div>

                  </Box>
                )}

              </Box>

            </List>
            {(students?.data ?? [])?.length > 0 ? <div style={{flex: 1, display: "flex", flexDirection: "row-reverse"}}>
              <TableFooter>
                <TableRow>
                  <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={3}
                      count={(students?.totalCount) ?? 10}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelDisplayedRows={(page) =>
                          `${page.from}-${page.to === -1 ? page.count : page.to} of ${
                              page.count
                          }`
                      }
                      ActionsComponent={TablePaginationActions}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </div> : null}
          </Paper>
        </Container>
      </Box>
      {/* <SchoolDetail school={selectedStudent} /> */}
      <AddStudent />
      {/* <Menu>
                <MenuList sx={{width: 320, maxWidth: '100%'}}>
                    <MenuItem sx={{color: 'wyarning.main',}}>
                        <ListItemIcon sx={{color: 'inherit'}}>
                            <Block fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Deactivate</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            ⌘X
                        </Typography>
                    </MenuItem>
                    <MenuItem sx={{color: 'success.main',}}>
                        <ListItemIcon sx={{color: 'inherit'}}>
                            <ThumbUpRounded fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Approve</ListItemText>
                    </MenuItem>
                    <MenuItem sx={{color: 'error.main',}}

                              onClick={handleDelete}
                    >
                        <ListItemIcon sx={{color: 'inherit'}}>
                            <DeleteRounded fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                    <Divider/>
                    <MenuItem sx={{color: 'primary.main',}}>
                        <ListItemIcon sx={{color: 'inherit'}}>
                            <ArchiveRounded fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Archive</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>*/}
    </>
  );
};

export default StudentListPage;
function refresh() {
    throw new Error("Function not implemented.");
}

