import {BrowserRouter as Router, Switch} from 'react-router-dom';
import AppContextProvider from './contexts/AppContext';
import ThemeModeContextProvider from './contexts/ThemeModeContext';
import {RoutesWrapper} from './wrapper/Routes.wrapper';
import './App.css'
import Snackbar from './components/Snackbar';
import LoadingBackdrop from './components/Backdrop';
import {HelmetProvider} from 'react-helmet-async';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import store, {persistor} from './redux/store';
import DeleteDialog from './components/dialogs/Delete.dialog';
import ErrorBoundary from './components/ErrorBoundary';
import GlobalLoadingIndicator from './components/GlobalLoadingIndicator';
import IsLockedDialog from "./components/dialogs/IsLocked.dialog";
import LogoutDialog from "./components/dialogs/Logout.dialog";


import 'offline-js'
import IsApproveDialog from "./components/dialogs/IsApproved.dialog";
import {SnackbarProvider} from 'notistack';
import {useEffect} from "react";
import "preline/preline";
import {IStaticMethods} from "preline/preline";

const queryClient = new QueryClient();

declare global {
	interface Window {
		HSStaticMethods: IStaticMethods;
	}
}

function App() {
	/*var miutablepagination = document.querySelectorAll(".MuiTablePagination-displayedRows").forEach((value)  =>{

        var test = value?.innerHTML
        var splitByDash = test?.split("-")
        var splitByOf = splitByDash[1]?.split(" of ")
        if(splitByOf?.[0] == splitByOf?.[1] ){
            value.innerHTML = splitByDash?.[0] + " of "+ splitByOf?.[1]
        }
    })
*/

	useEffect(() => {
		window.HSStaticMethods?.autoInit();
	}, [location.pathname]);
	return (
		<HelmetProvider>

			<QueryClientProvider client={queryClient}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<SnackbarProvider>
							<AppContextProvider>
								<ThemeModeContextProvider>
									<ErrorBoundary>
										<Snackbar/>
										<GlobalLoadingIndicator/>
										<IsLockedDialog/>
										<IsApproveDialog/>
										<DeleteDialog/>
										<LogoutDialog/>
										<LoadingBackdrop/>

										<Router>
											<Switch>
												<RoutesWrapper />
											</Switch>
										</Router>
									</ErrorBoundary>
								</ThemeModeContextProvider>
							</AppContextProvider>
							</SnackbarProvider>
						</PersistGate>
					</Provider>
				</QueryClientProvider>
			</HelmetProvider>

	);
}


export default App;
