import {DeleteRounded, FilterAltRounded, ViewColumnRounded,} from "@mui/icons-material";
import {Grid, IconButton, Paper, Stack, TableCell, TableRow, TextField, Tooltip, Typography,} from "@mui/material";
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions, TableFilterList, TableToolbar,} from "mui-datatables";
import React, {FC, ReactNode, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import useDialog from "../../hooks/useDialog";
import {useUploadQuery} from "../../hooks/useUploadQuery";
import {setLoading} from "../../redux/features/app";
import {EnrollmentType} from "../../types";
import StudentInfoDialog from "../dialogs/StudentInfo.dialog";
import {useDelete} from "../../hooks/useDelete";
import {usePointerTheme} from "../../hooks/usePointerTheme";
import {deleteStudent, getStudent} from "../../requests/student.request";
import {useHistory, useParams} from "react-router-dom";
import {getPromotionalReportById} from "../../requests/promotionalReport.request";
import flat from "flat";
import {useQueryClient} from "react-query";
import {usePager} from "../../hooks/usePager";
import {useDebounce} from "../../hooks/useDebounce";
import {useNotificationPromotionReportQuery} from "../../hooks/useNotificationPromotionReportQuery";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";

interface IPromotionalReportTableProps {
    title: string;
}


const EnrollmentTable: FC<IPromotionalReportTableProps> = ({title}) => {
    const history = useHistory();
    const {enrollmentId} = useParams() as {
        enrollmentId: string
    };
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const { data: downloadPr } = useNotificationPromotionReportQuery({
        pageSize: 100,
        seen: false,
        enrollmentId,
        ...title && {header:title}
    });
    const [filters, setFilters] = useState({
    programId: "all",
  });
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)

    const {
    open: isOpenStudentInfo,
    handleClose: handleCloseStudentInfo,
    handleOpen: handleOpenStudentInfo,
    data: student,
    setData: setStudent,
  } = useDialog<EnrollmentType>();
  const { data: uploadedData, isLoading } = useUploadQuery({
      ...(pageSize && {pageSize: pageSize}),
      ...(page && {page: page + 1}),
    filters
  });
    const totalCount = useMemo(() => {
        return (uploadedData?.totalCount)
    }, [uploadedData?.totalCount])
    const totalPage = useMemo(() => {
        return (uploadedData?.totalPages)
    }, [uploadedData?.totalPages])
    const totalRowPage = useMemo(() => {
        return (uploadedData?.pageSize)
    }, [uploadedData?.pageSize])
    const {changePage, changeRowsPerPage} = usePager(setPage, setPageSize);
    const [searchKey, setSearchKey] = React.useState<any>("");
    const debouncedSearchKey = useDebounce(searchKey, 500);
    useEffect(() => {
        const load = async () => {
            const res = await getPromotionalReportById(enrollmentId, page, pageSize, debouncedSearchKey);
            queryClient.setQueriesData("upload", (old: any) => {
                return {
                    ...res,
                    enrollments: res.data.map((el) => {
                        return {
                            ...el,
                            ...(flat(el, { safe: true }) as object),
                        };
                    }),
                    programs: res.programs,
                };
            });
        }

        load()
    }, [page, pageSize, debouncedSearchKey  ]);


  const handleDelete = useDelete(
      "Delete Student",
      "enrollment",
      deleteStudent,

  );

    const onRowClick = (id: string) => {
      dispatch(setLoading(true))
      const row = uploadedData?.enrollments?.find(
          (enrollment) => enrollment.student.id === +id
      );
      setStudent(row);

      if (id) {
          getStudent(+id).then((res) => {
              handleOpenStudentInfo();
              dispatch(setLoading(false))
          }).catch(() => {
              handleOpenStudentInfo();
              dispatch(setLoading(false))
          })
      }

      //   history.push(`/students/${ +id}`, { studentId: +id, from: '/enrollments/' + row?.id, title })

  };

  const onDeleteRow = (e: any, name: any) => {
   return  handleDelete(e, name )
  }

  const columns: MUIDataTableColumn[] =     [
      {
        name: "student.id",
        label: "ID",
        options: {
          display: false,
          filter: false,
          sort: true,
            setCellHeaderProps: () => { return { align:"center"} },
            setCellProps: () => { return { align:"center"} },
        },
      },
      {
        name: "student.studentNumber",
        label: "STUDENT NO.",
        options: {
          filter: false,
          sort: true,
            setCellHeaderProps: () => { return { align:"center"} },
            setCellProps: () => { return { align:"center"} },
        },
      },
      {
        name: "student.firstName",
        label: "FIRST NAME",
        options: {
          filter: true,
          sort: true,
            setCellHeaderProps: () => { return { align:"center"} },
            setCellProps: () => { return { align:"center"} },
        },
      },
      {
        name: "student.lastName",
        label: "LAST NAME",
        options: {
          filter: true,
          sort: true,
            setCellHeaderProps: () => { return { align:"center"} },
            setCellProps: () => { return { align:"center"} },
        },
      },
      {
        name: "student.middleName",
        label: "MIDDLE NAME",
        options: {
          filter: true,
          sort: true,
            setCellHeaderProps: () => { return { align:"center"} },
            setCellProps: () => { return { align:"center"} },
        },
      },
      {
        name: "student.extName",
        label: "EXTENSION NAME",
        options: {
            setCellHeaderProps: () => { return { align:"center"} },
            setCellProps: () => { return { align:"center"} },
          filter: true,
          sort: true,
        },
      },
      {
        name: "student.sex",
        label: "SEX",

        options: {
          filter: true,
            setCellHeaderProps: () => { return { align:"center"} },
            setCellProps: () => { return { align:"center"} },
          sort: true,
        },
      },
      {
        name: "yearLevel",
        label: "YEAR LEVEL",
        options: {
            setCellHeaderProps: () => { return { align:"center"} },
            setCellProps: () => { return { align:"center"} },
          filter: true,
          sort: true,
        },
      },
      {
        name: "student.id",
        label: "ACTION",
        options: {
            display: false,
          filter: false,
          sort: false,

            setCellHeaderProps: () => { return { align:"center"} },
            setCellProps: () => { return { align:"center"} },
          viewColumns: false,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

            const id = tableMeta.rowData[columns.findIndex((x) => x.name === "id")];
            const name = tableMeta.rowData[columns.findIndex((x) => x.name === "student.firstName")];
            const middlename = tableMeta.rowData[columns.findIndex((x) => x.name === "student.middleName")];
            const lastname = tableMeta.rowData[columns.findIndex((x) => x.name === "student.lastName")];

              //uploadedData?.data.m

            return (
                <Stack direction="row" spacing={1}>
                  <Tooltip aria-label={"tool-delete-pr"}   title="Delete" arrow>
                    <IconButton
                        aria-label={"icon-delete-pr"}
                       id={"icon-delete-pr"}
                        color="error"
                        size="small"
                        value={value}
                        onClick={(e) =>{

                            e.preventDefault()
                          e.stopPropagation()
                            onDeleteRow(e, name + " " + middlename + " " + lastname)
                        }
                        }
                    >
                      <DeleteRounded />
                    </IconButton>
                  </Tooltip>
                </Stack>
            );
          },
        },
      },
      {
        name: "program.name",
        label: "PROGRAM",
        options: {
          display: false,
          viewColumns: false,
          filter: true,
        },
      },
      {
          name: "_data", // put always to last
          options: {
              viewColumns: false,
              display: false,
              filter: false,
          },
      },
  ];

    const options: MUIDataTableOptions = {
        textLabels: {
            body: {
                noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
            }
        },
        onTableChange: async (action, newTableState: any) => {
            switch (action) {
                case "changeRowsPerPage":
                    changeRowsPerPage(newTableState.rowsPerPage);
                    break;
                case "search":
                    setSearchKey(newTableState.searchText);
                    break;
                case "changePage":
                    changePage(newTableState.page, (newTableState.data?.length ?? 100));
                    break;
                case "rowExpansionChange":
                    /*if (newTableState?.curExpandedRows?.[0]?.index > -1) {
                                  var _programsExpansion = programs?.data?.[newTableState?.curExpandedRows?.[0]?.index]
                                  if (_programsExpansion?.subject_Programs == null) {
                                      var curriculum = await getCurriculums({
                                          pageSize: 100,
                                          schoolId: schoolId,
                                          ...(_programsExpansion.id && {programId: _programsExpansion.id})
                                      })
                                      dispatch(setUpdateProgramsCurriculumByIndex(
                                          { index: newTableState?.curExpandedRows?.[0]?.index, curriculum: curriculum?.data} ))

                                  }
                                  //dispatch(setUpdateProgramsByIndex(newTableState?.curExpandedRows?.[0]?.index))


                              }*/
                    break;
            }
        },
        serverSide: true,
        filterType: "dropdown",
        tableBodyMaxHeight: "600px",
        print: false,
        tableId: "enrollmenttable",
        download: false,
        count: totalCount as any,
        rowsPerPage: totalRowPage,
        page: Math.min(typeof totalCount === "number" ? totalCount : 10, page),
        onRowClick: (rowData) => {
            onRowClick(rowData?.[0]);
        },
        isRowExpandable: () => true,
      expandableRows: true,
      expandableRowsHeader: false,
      renderExpandableRow: (rowData, rowMeta) => {
          const enrollmentData = rowData[
        rowData.length - 1
      ] as unknown as EnrollmentType & { _acadYear: string };
      const colSpan = rowData.length + 1;

      return (
        <>
          <TableRow>
            <TableCell
              colSpan={colSpan}
              sx={{
                px: 2,
              }}
            >
              <Typography>
                Program:{" "}
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  {enrollmentData?.program?.name}
                </Typography>
              </Typography>
              <Typography>
                Major:{" "}
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  {enrollmentData?.program?.major?.name}
                </Typography>
              </Typography>
              <Typography>
                Year level:{" "}
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  {enrollmentData?.yearLevel}
                </Typography>
              </Typography>
            </TableCell>
          </TableRow>
        </>
      );
      },
    };

    useEffect(() => {
        dispatch(setLoading(isLoading));
    }, [isLoading]);



    return (
        <>
            <Paper elevation={0} sx={{ p: 2, my: 2 }}>
                <Typography>School Information</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack>
                            <TextField
                                required
                                id="schoolInstutionCode"
                                name="schoolInstutionCode"
                                label="School Institutional Code"
                                fullWidth
                                margin="normal"
                                size="small"
                                value={uploadedData?.institutionalCode || ""}
                                InputProps={{
                                    "aria-readonly": true,
                                    readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                required
                                id=""
                                name="totalStudents"
                                label="Total No. Students"
                                fullWidth
                                margin="normal"
                                size="small"
                                value={uploadedData?.totalCount || 0}
                                InputProps={{
                                    "aria-readonly": true,
                                    readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                required
                                id="schoolEmail"
                                name="schoolEmail"
                                label="School Email"
                                fullWidth
                                margin="normal"
                                size="small"
                                value={uploadedData?.email || ""}
                                InputProps={{
                                    "aria-readonly": true,
                                    readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack>
                            <TextField
                                required
                                id="schoolName"
                                name="schoolName"
                                label="School Name"
                                fullWidth
                                margin="normal"
                                size="small"
                                value={uploadedData?.schoolName || ""}
                                InputProps={{
                                    "aria-readonly": true,
                                    readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                required
                                id="schoolAddress"
                                name="schoolAddress"
                                label="School Address"
                                fullWidth
                                value={uploadedData?.schoolAddress || ""}
                                margin="normal"
                                size="small"
                                InputProps={{
                                    "aria-readonly": true,
                                    readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                required
                                id="shoolContactNumber"
                                name="shoolContactNumber"
                                label="School Contact Number"
                                fullWidth
                                InputProps={{
                                    "aria-readonly": true,
                                    readOnly: true,
                                }}
                                value={uploadedData?.contactNumber || ""}
                                margin="normal"
                                size="small"
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack>
                            <TextField
                                required
                                id="preparedBy"
                                name="preparedBy"
                                label="Prepared By"
                                fullWidth
                                value={uploadedData?.preparedBy || ""}
                                margin="normal"
                                size="small"
                                InputProps={{
                                    "aria-readonly": true,
                                    readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                required
                                id="approvedBy"
                                name="approvedBy"
                                label="Approved By"
                                fullWidth
                                margin="normal"
                                size="small"
                                value={uploadedData?.approvedBy ?? ""}
                                InputProps={{
                                    "aria-readonly": true,
                                    readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                required
                                id=""
                                name="status"
                                label="Status"
                                fullWidth
                                margin="normal"
                                size="small"
                                value={uploadedData?.status}
                                InputProps={{
                                    "aria-readonly": true,
                                    readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
            {(downloadPr?.data as any)?.promotionalReportLink ?
                <a href={(downloadPr?.data as any)?.promotionalReportLink}  download={ "_" +"Unsuccessful_PR_EL.xlsx"} style={{textDecoration: 'none'}}>
                    <Button variant="contained" color="info" style={{marginBottom: 12}} startIcon={<DownloadIcon/>}>
                        Download PR
                    </Button>
                </a> : null}
            <MUIDataTable
                title={title}
                data={uploadedData?.enrollments as any}
                columns={columns}
                options={options}
                components={{
            TableFilterList: (props) => <TableFilterList {...props} />,
            TableToolbar: (props) => {
                return <TableToolbar {...props} />;
            },
            icons: {
                FilterIcon: FilterAltRounded as unknown as ReactNode,
                ViewColumnIcon: ViewColumnRounded as unknown as ReactNode,
            },
        }}
      />


        <StudentInfoDialog
            open={isOpenStudentInfo}
            handleClose={handleCloseStudentInfo}
            student={student}
        />
    </>
  );
};

export default EnrollmentTable;
