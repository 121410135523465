import {
    AddRounded, Block,
    CheckBox,
    CopyAllOutlined,
    DeleteRounded,
    EditRounded,
    FilterAltRounded, MoreHoriz, MoreVert, SendRounded,
    SettingsRounded, ThumbUpRounded,
    ViewColumnRounded, WatchLater,
} from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Chip, DialogContentText,
    FormControl,
    IconButton,
    ListItemIcon, ListItemText,
    MenuItem, MenuList, Select, SelectChangeEvent,
    Stack,
    TableCell,
    TableRow, TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import moment from 'moment-timezone';
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions, TableFilterList, TableToolbar,} from "mui-datatables";
import React, {FC, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeStatus, deleteProgram, getProgramById, isApprovedProgram} from "../../requests/program.request";
import AddProgram from "../dialogs/AddProgram.dialog";
import {RootState, useAppSelector} from "../../redux/store";
import {
    setCurriculum,
    setCurriculumBreadcrumb,
    setCurriculumId,
    setProgram,
    setProgramBreadcrumb,
    setProgramId,
    setProgramTabState,
    setTabValue,
} from "../../redux/features/school";
import {useAddEntities} from "../../hooks/useAddEntities";
import {useSearchProgramQuery} from "../../hooks/useSearchProgramQuery";
import {useDebounce} from "../../hooks/useDebounce";
import {setDeleteDialog, setLoading} from "../../redux/features/app";
import {usePager} from "../../hooks/usePager";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import useDialog from "../../hooks/useDialog";
import ConfigurationRequirement from "../dialogs/ConfigurationRequirement.dialog";
import {
    setConfigurationProgram,
    setEditDisciplineId,
    setEditDisciplineName,
    setEditLevelId,
    setEditLevelName,
    setEditMajorId,
    setEditMajorName,
    setEditName,
    setEditProgramId,
    setIsApproveDialog
} from "../../redux/features/program";
import EditProgramDialog from "../dialogs/EditProgram.dialog";
import useMenuDialog from "../dialogs/Menu.dialog";
import InputLabel from "@mui/material/InputLabel";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import {CheckCircleIcon} from "lucide-react";
import {useQueryClient} from "react-query";
import {LoadingButton} from "@mui/lab";

interface IConfigurationRequirementTableProps {
    title: string;
    setting?: boolean;
}

const ConfigurationRequirementTable: FC<IConfigurationRequirementTableProps> = (props) => {
    const queryClient = useQueryClient()
    const dispatch = useDispatch();
    const {title, setting} = props;
    const {schoolId, regionId} = useAddEntities();
  const disciplineId = useAppSelector((store) => store?.school?.disciplineId);
  const programs = useAppSelector((store) => store?.program?.programs);
  const [page, setPage] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const [changeStatusLoading, setChangeStatusLoading] = useState<boolean>(false)
  const [name, setName] = React.useState<any>("");
    const [selectedProgramId, setSelectedProgramId] = React.useState<number |string | null>(null)
    const [selectedProgramName, setSelectedProgramName] = React.useState("")
    const [selectedSchoolName, setSelectedSchoolName] = React.useState("")
    const [selectedStatus, setSelectedStatus] = React.useState("")
    const [selectedSchoolId, setSelectedSchoolId] = React.useState<number | null>(null)
      const debouncedName = useDebounce(name, 500);
  const regionSetting = useAppSelector((store) => store?.school.regionSetting);
   const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);
    const roles = useAppSelector((store) => store?.auth?.user?.roles);
    const [dropDownStatus, setDropDownStatus] = React.useState('');
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const dropDownHandleChange = (event: SelectChangeEvent) => {
        setDropDownStatus(event.target.value);

    };


    var chedAdmin = {
        submitted: <MenuItem
            sx={{color: 'warning.main',}}
            value="approved"
            onClick={() => changeStatus( selectedProgramId, "approved", selectedSchoolId)}
        >
            <ListItemIcon sx={{color: 'inherit'}}>
                <WatchLater fontSize="small"/>
            </ListItemIcon>
            <ListItemText>For Approval</ListItemText>
        </MenuItem>
    };




    const {
        Component: Menu,
        handleClick: handleClickOpenMenu,
        handleClose: handleCloseMenu
    } = useMenuDialog({onClose: () => {}})
  const handleDeleteProgram = (id: any, name: string) => {
    dispatch(
      setDeleteDialog({
        open: true,
          data: {
              id,
              title: `Delete Program - ${name}?`,
              content: "program",
              body: `Before deleting this program, please note that by clicking the "Delete" button, all associated entities will be removed from the system. Are you sure you want to proceed?`,
              onDelete: async () => await deleteProgram(+id),
          },
      })
    );
  };
    const {
        handleOpen: handleOpenEditProgram,
        handleClose: handleCloseEditProgram,
        open: openEditProgram,
    } = useDialog();
    const {data: programData, isLoading, refetch} = useSearchProgramQuery(debouncedName, {
        ...(schoolId && {schoolId}),
        pageSize: 100,
        status: dropDownStatus,
        ...(regionSetting?.id && {regionId: regionSetting?.id}),
        ...(regionId?.id && {regionId: regionId?.id}),
        ...(typeof regionId == "number" && {regionId: regionId}),
        ...(debouncedName && {search: debouncedName}),
        ...(pageSize && {pageSize: pageSize}),
        ...(page && {page: page + 1}),
        ...(disciplineId && {disciplineId: disciplineId}),
    });
    const selectedProgram = useMemo(() => {
        return programData?.data ?.find((program) => program.id === selectedProgramId)
    }, [selectedProgramId])
    const onRowClick = useCallback((e: MouseEvent<HTMLButtonElement>, schoolId: number, schoolName: string, programName: string, programId: number) => {
        e.stopPropagation();
        setSelectedProgramId(programId)
        setSelectedProgramName(programName)
        setSelectedSchoolId(schoolId)
        setSelectedSchoolName(schoolName)
        handleClickOpenMenu(e)
    }, [selectedProgramId, selectedProgramName, selectedSchoolName, selectedSchoolId])
    const totalCount = useMemo(() => {
        return (programData?.totalCount)
    }, [programData?.totalCount])
    const totalPage = useMemo(() => {
        return (programData?.totalPages)
    }, [programData?.totalPages])
    const totalRowPage = useMemo(() => {
        return (programData?.pageSize)
    }, [programData?.pageSize, selectedProgramId, selectedSchoolId])
    const {changePage, changeRowsPerPage} = usePager(setPage, setPageSize);


    const type = useSelector((store: RootState) => store.auth?.user?.type)
    const expandedRow = (curriculum: any) => {
        dispatch(setTabValue(2));
        dispatch(setCurriculumId(curriculum.id));
        dispatch(setCurriculumBreadcrumb(curriculum.name));
        dispatch(setCurriculum(curriculum));
    };

    const handleSetting = (e: any, id: string | number | undefined, name: string | number | undefined, schoolId: string | number | undefined) => {
        e.stopPropagation();
        dispatch(setConfigurationProgram({
            id: id, name: name, schoolId: schoolId
        }))
        handleOpenConfigurationForRequirement()
    }


    const columns: MUIDataTableColumn[] = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            },
    },
    {

      name: "school",
      label: "SCHOOL",
      options: {

        display: type?.id == 2 || type?.id == 3 || roles?.toLowerCase() == "superadmin",
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta, updateValue: any) => {
          const name =
              tableMeta.rowData[columns.findIndex((x) => x.name === "name")];
          return (
              <Typography fontSize={15} sx={{wordBreak: "break-word"}}>
                {value}
              </Typography>
          );
        }
      },
    },
     {
      name: "name",
      label: "Name of Program".toUpperCase(),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta, updateValue: any) => {
          const name =
              tableMeta.rowData[columns.findIndex((x) => x.name === "name")];
          return (

                 <Typography fontSize={15} sx={{ textDecoration: "underline",  wordBreak: "break-word" }} >
                   {value}
                   <Tooltip title="Copy to clipboard" arrow>
                     <IconButton
                        // color="error"
                         size="small"
                         onClick={(e) => {
                           e.stopPropagation();
                           try {
                             navigator.clipboard.writeText(name)
                             dispatch(
                                 openSnackbar({
                                   open: true,
                                   message: "Copy To Clipboard",
                                   severity: "success",
                                 })
                             );
                           }catch (e) {

                           }
                         }}
                     >
                       <CopyAllOutlined/>
                     </IconButton>
                   </Tooltip>
                 </Typography>


          );
        },
      },
    },
    {
      name: "major.name",
      label: "MAJOR",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta, updateValue: any) => {
          const name =
              tableMeta.rowData[columns.findIndex((x) => x.name === "major.name")];
          return (

              <Typography fontSize={15} sx={{textDecoration: "underline", wordBreak: "break-word"}}>
                {value}
                <Tooltip title="Copy to clipboard" arrow>
                  <IconButton
                      // color="error"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        try {
                          navigator.clipboard.writeText(name)
                          dispatch(
                              openSnackbar({
                                open: true,
                                message: "Copy To Clipboard",
                                severity: "success",
                              })
                          );
                        } catch (e) {

                        }
                      }}
                  >
                    <CopyAllOutlined/>
                  </IconButton>
                </Tooltip>
              </Typography>


          );
        },
      },
    },
    {
      name: "level.name",
      label: "Level",
      options: {
        filter: true,
        sort: false,
      },
    },


    {
      name: "dateCreated",
      label: "DATE CREATED",
      options: {
        display: false,
        filter: true,
        sort: false,
        customBodyRenderLite: (value: any) => {
          return moment(value).format("lll");
        },
      },
    },
    {
      name: "lastModifiedDate",
      label: "DATE UPDATED",
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRenderLite: (value: any) => {
          return moment(value).format("lll");
        },
      },
    },{
      name: "isApproved",
      label: "isApproved",
      options: {
        display: false,
        filter: false,
        sort: false
      },
    },
    {
      name: "id",
      label: "ACTION",
      options: {
        display: true,
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value: any, tableMeta, updateValue: any) => {
            const _name =
                tableMeta.rowData[columns.findIndex((x) => x.name === "name")];
            const _id =
                tableMeta.rowData[columns.findIndex((x) => x.name === "id")];
            const _schoolId =
                tableMeta.rowData[columns.findIndex((x) => x.name === "schoolId")];
            const _schoolName =
                tableMeta.rowData[columns.findIndex((x) => x.name === "school")];
const _status =
    tableMeta.rowData[columns.findIndex((x) => x.name === "status")];


            const _isApproved =
                tableMeta.rowData[columns.findIndex((x) => x.name === "isApproved")];
            return (
                <Stack direction="row" spacing={1}>


                    { type?.id == 2 || type?.id == 3 ?
                        <Tooltip title="Configure Requirement" arrow>
                            <IconButton
                                color="primary"
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSetting(e, _id, _name, _schoolId);
                                }}
                            >
                                <SettingsRounded/>
                            </IconButton>
                        </Tooltip>  : null
                    }

                </Stack>
            );
        },
      },
    },
        {
            name: "schoolId",
            label: "SchoolId",
            options: {
                display: false,
                filter: true,
                sort: false,

            },
        },
    ];
  const handleRowClick = (rowData: any, rowMeta: any) => {
    const name = rowData[columns.findIndex((x) => x.name === "name")];
    dispatch(setTabValue("curriculum"));
    dispatch(setProgramId(rowData[0]));
    dispatch(setProgramBreadcrumb(name?.props?.children?.[0] ?? name));
    dispatch(setProgram(programData?.data?.[rowMeta.rowIndex]));
  };
  const options: MUIDataTableOptions = {
    textLabels: {
      body: {
          noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
      }
    },
      print: false,
      filter: false,
      tableId: "programtable",
      download: type?.id != 1,
      selectableRows: "none",
      serverSide: true,
      count: totalCount,
      rowsPerPage: totalRowPage,
      page: Math.min(typeof totalCount === "number" ? totalCount : 10, page),
      onTableChange: async (action, newTableState: any) => {
          switch (action) {
              case "changeRowsPerPage":
                  changeRowsPerPage(newTableState.rowsPerPage);
                  break;

              case "changePage":
                  changePage(newTableState.page, (newTableState.data?.length ?? 100));
                  break;
        case "rowExpansionChange":
          /*if (newTableState?.curExpandedRows?.[0]?.index > -1) {
                        var _programsExpansion = programs?.data?.[newTableState?.curExpandedRows?.[0]?.index]
                        if (_programsExpansion?.subject_Programs == null) {
                            var curriculum = await getCurriculums({
                                pageSize: 100,
                                schoolId: schoolId,
                                ...(_programsExpansion.id && {programId: _programsExpansion.id})
                            })
                            dispatch(setUpdateProgramsCurriculumByIndex(
                                { index: newTableState?.curExpandedRows?.[0]?.index, curriculum: curriculum?.data} ))

                        }
                        //dispatch(setUpdateProgramsByIndex(newTableState?.curExpandedRows?.[0]?.index))


                    }*/
          break;
        case "search":
          setPage(0)
          setName(newTableState.searchText);
          break;
      }
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      return true;
    },
    expandableRows: false,
    expandableRowsHeader: false,
    onRowClick: handleRowClick,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length - 1;
      const data = programData?.data[rowMeta.rowIndex] as any;

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            {data?.curriculums?.map((curriculum: any) => {
              return (
                <Box key={curriculum.id}>
                  <Button onClick={() => expandedRow(curriculum)}>
                    {curriculum?.name}
                  </Button>
                </Box>
              );
            })}
          </TableCell>
        </TableRow>
      );
    },
  };

  const programTabOpen = useAppSelector(
    (store) => store?.school.programTabOpen
  );

  function handleCloseAddProgram() {
      dispatch(setProgramTabState(false));
  }

    async function onChangeStatusSubmit() {
      try {
          setChangeStatusLoading(true)
          await changeStatus(selectedProgramId, selectedStatus, selectedSchoolId)
          queryClient.setQueriesData('program', (oldData: any) => {
            var _oldData = JSON.parse(JSON.stringify(oldData));
          return {
              ..._oldData,
              data: _oldData?.data?.map((sor: any) => {
                  if (sor.id == selectedProgramId) {
                      sor.status = selectedStatus
                  }
                  return sor
              })
          }
          })
          setChangeStatusLoading(false)
          setSelectedProgramId(0)
          setSelectedProgramName("")
          setSelectedSchoolId(0)
          setSelectedSchoolName("")

          dispatch(
              openSnackbar({
                  open: true,
                  message: "Your changes have been successfully updated.",
                  severity: "success",
              })
          );
          handleCloseProgramStatus()
      }catch (e) {
          setChangeStatusLoading(false)
          dispatch(
              openSnackbar({
                  open: true,
                  message: "Something went wrong",
                  severity: "success",
              })
          );
      }

    }
 function handleOpenAddProgram() {
        dispatch(setProgramTabState(true));
    }

    useEffect(() => {
        refetch();
    }, [programTabOpen]);


    const {
        handleOpen: handleOpenConfigurationForRequirement,
        handleClose: handleCloseConfigurationForRequirement,
        open: openConfigurationForRequirement,
    } = useDialog();


    const {
        handleOpen: handleOpenProgramStatus,
        handleClose: handleCloseProgramStatus,
        open: openProgramStatus,
    } = useDialog();

    return (
        <>
            <MUIDataTable

                title={title}
                data={programData?.data as any}
                columns={columns}
                options={options}
        components={{

          TableFilterList: (props) => {
            return <TableFilterList {...props} />;
          },

          TableToolbar: (props) => {
            return (
              <>
                <TableToolbar {...props} />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mx: 3,
                    my: 1,
                  }}
                >
                  <Stack direction="row" gap={1}>
                      {(typeId == 2 && (roles.toLowerCase() === "admin" || roles.toLowerCase() === "user")) ?
                          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                              <InputLabel id="sort-status-select-small-label">SORT BY: STATUS</InputLabel>
                              <Select
                                  labelId="sort-status-select-small-label"
                                  id="sort-status-select-small"
                                  value={dropDownStatus}
                                  label="SORT BY: STATUS"
                                  onChange={dropDownHandleChange}
                              >
                                  <MenuItem value="">
                                      <em>All</em>
                                  </MenuItem>
                                  <MenuItem value={"approved"}>Approved</MenuItem>
                                  <MenuItem value={"submitted"}>For Approval</MenuItem>
                              </Select>
                          </FormControl> : null
                      }

                  </Stack>

                    <Box>

                        {(type?.id == 2 || type?.id == 1 || type?.id == 3) ? <Button
                            startIcon={<AddRounded/>}
                            variant="contained"
                            onClick={handleOpenAddProgram}
                        >
                            Add Program
                        </Button> : null}
                    </Box>


                </Box>
              </>
            );
          },
          icons: {
            FilterIcon: FilterAltRounded as unknown as ReactNode,
            ViewColumnIcon: ViewColumnRounded as unknown as ReactNode,
          },
        }}></MUIDataTable>

            <AddProgram setting={setting} open={programTabOpen} handleClose={handleCloseAddProgram}/>
            <EditProgramDialog setting={setting} open={openEditProgram} handleClose={handleCloseEditProgram}/>
            <ConfigurationRequirement open={openConfigurationForRequirement}
                                      handleClose={handleCloseConfigurationForRequirement} onSave={() => {

            }} title={"Configuration For Requirements"}/>

            <Dialog
                open={openProgramStatus}
                onClose={handleCloseProgramStatus}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Do you want to ${selectedStatus == "submitted" ? "submit" : selectedStatus } ${selectedSchoolName} - ${selectedProgramName}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseProgramStatus}>No</Button>
                    <LoadingButton loading={changeStatusLoading} onClick={() => onChangeStatusSubmit()} autoFocus>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>

    </>
  );
};

export default ConfigurationRequirementTable;
