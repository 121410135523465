import * as React from 'react';
import {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {Alert, Box, Checkbox, FormControlLabel, FormGroup, IconButton} from '@mui/material';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
import {ISnackbarSlice, openSnackbar} from '../../redux/features/snackbar';
import {useQueryClient} from 'react-query';
import {useAppSelector} from "../../redux/store";
import {getUserApplication, patchUserApplication, postUserApplication} from "../../requests/userApplication.request";
import {mergeArrays} from "../../helper/utils/helper";
import {LoadingButton} from "@mui/lab";
import {CloseRounded} from "@mui/icons-material";

const validationSchema = yup.object({
    applications: yup.array(
        yup.object({
            id: yup.number(),
            name: yup.string(),
            applicationId: yup.number(),
            isEnabled: yup.boolean()
        })
    )
})

const initialValues = {
    applicationIds: [] as any,
    userId: "",
    userApplications: [
        {
            "id": 0,
            "applicationId": 1,
            "name": "Dashboard",
            "isEnabled": false
        },
        {
            "id": 0,
            "applicationId": 2,
            "name": "Schools",
            "isEnabled": false
        },
        {
            "id": 0,
            "applicationId": 3,
            "name": "Students",
            "isEnabled": false
        },
        {
            "id": 0,
            "applicationId": 4,
            "name": "Enrollments",
            "isEnabled": false
        },
        {
            "id": 0,
            "applicationId": 5,
            "name": "Settings",
            "isEnabled": false
        },
        {
            "id": 0,
            "applicationId": 6,
            "name": "Logs",
            "isEnabled": false
        }
    ]
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface IProps {
    fullName: string
    open: boolean
    handleClose: () => void
    userId: string
}

const UserApplication = React.memo((props: IProps) => {
    const {open, handleClose, userId, fullName} = props

    const roles = useAppSelector((store) => store?.auth?.user?.roles)

    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])


    const patchUserApplication_ = async (userApplicationId: any) => {
        return await patchUserApplication(userApplicationId)
    }
    const [loading, setLoading] = useState(false)
    const [patchIsLoading, setPatchIsLoading] = useState(false)
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {

            try {
                setLoading(true)
                values.userApplications.map(c => {
                    if (c?.applicationId?.toString() == values?.applicationIds.find((va: string | number) => +va == c.applicationId)) {
                        c.isEnabled = true
                    }
                    return c
                })
                values.userId = userId
                await postUserApplication(values)
                setSnackbarStore({
                    message: "Updated Access Role Successfully!",
                    severity: "success",
                    open: true,
                })
                setLoading(false)
                // actions.resetForm()
                queryClient.invalidateQueries('users')
                handleClose()
            } catch (error: any) {
                const errorMsg = error?.response?.data?.message || error.message || 'Something went wrong please try again'
                setSnackbarStore({
                    message: errorMsg,
                    severity: "error",
                    open: true,
                })
            }
        }
    })

    const [userClickCount, setUserClickCount] = useState(0)
    useEffect(() => {

        setPatchIsLoading(true)
        try {
            getUserApplication(userId).then(async c => {
                setPatchIsLoading(false)
                if (c.data?.length == 0) {
                    initialValues.userId = userId
                    await postUserApplication(initialValues)
                    getUserApplication(userId).then(async c => {
                        formik.setFieldValue("applicationIds", c.data.filter((c: {
                            isEnabled: any;
                        }) => c.isEnabled).map((c: {
                            applicationId: any;
                        }) => c.applicationId?.toString()))
                        if (c.data?.length > 0) {
                            formik.setFieldValue("userApplications", c.data)
                            setPatchIsLoading(false)
                        } else {
                            formik.setFieldValue("userApplications", mergeArrays([initialValues.userApplications, c.data], 'applicationId'))
                            setPatchIsLoading(false)
                        }
                    })
                }
                formik.setFieldValue("applicationIds", c.data.filter((c: { isEnabled: any; }) => c.isEnabled).map((c: {
                    applicationId: any;
                }) => c.applicationId?.toString()))
                if (c.data?.length > 0) {
                    formik.setFieldValue("userApplications", c.data)
                    setLoading(false)
                } else {
                    formik.setFieldValue("userApplications", mergeArrays([initialValues.userApplications, c.data], 'applicationId'))
                    setLoading(false)
                }
            })
        } catch (e) {
            setLoading(false)
        }
        return () => {

        }
    }, [userId])
    const handleSubmit = React.useCallback(() => {
        formik.handleSubmit()
    }, [formik])


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                <div style={{flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div>
                        Access List
                    </div>
                    <div>
                        <IconButton onClick={handleClose}>
                            <CloseRounded/>
                        </IconButton>

                    </div>
                </div>

            </DialogTitle>
            <DialogContent>
                <Alert severity='info'>
                    {`Please Confirm: Review and confirm ${fullName} access list settings.`}
                    <br/>
                    This ensures {fullName} access preferences are accurately maintained.
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 3,
                    }}
                >
                    <FormGroup>
                        {initialValues.userApplications.map((app, i) => {
                            return <FormControlLabel name={"applicationIds"} key={i}
                                                     disabled={patchIsLoading}
                                                     checked={formik.values?.applicationIds?.includes(formik.values?.userApplications?.[i]?.applicationId?.toString())}
                                                     value={formik.values.userApplications?.[i]?.applicationId}
                                                     onChange={async (event: any, checked) => {
                                                         formik.values.userApplications[i].isEnabled = checked
                                                         formik.handleChange(event)
                                                     }}
                                                     control={<Checkbox/>}
                                                     label={formik.values.userApplications?.[i]?.name}/>
                        })}
                    </FormGroup>
                </Box>
            </DialogContent>
            <DialogActions style={{flex: 1, display: "flex", justifyContent: "center"}}>
                <LoadingButton loading={loading} id={"handlesavechangeUploadpr"} variant='contained' onClick={() => {

                    handleSubmit()
                }}>Save Changes</LoadingButton>
            </DialogActions>
        </Dialog>
    )
})

export default UserApplication
