import api from "./config"

export const getUnseen = async (seeMore: number) => {
    const res = await api.get("/notifications/unseen", {
        params:{
            seeMore
        }
    })
    return res?.data
}
export const markAsSeenRequest = async (notificationId: number) => {
    const res = await api.post(`/notifications/mark-as-seen`)
    return res?.data
}