import * as Yup from "yup";

export interface IMajorSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name?: string | null;
}

export const majorSchema: Yup.SchemaOf<IMajorSchema> = Yup.object().shape({
    id: Yup.number().required("Id is required"),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    name: Yup.string().nullable(),
});

export type MajorType = Yup.InferType<typeof majorSchema>;
export const majorInputSchema = Yup.object().shape({
    schoolId: Yup.string().required("School Id is required"),
    code: Yup.string().notRequired().nullable(),
    name: Yup.string().required("Name is required"),
});

export type MajorInputType = Yup.InferType<typeof majorInputSchema>;
